import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import BioCard from "../BioCard";
import FeedCard from "../FeedCard";
import RecommendationsWrapper from "../CommunicationCardWrapper";
import {
  Box,
  Grid,
  Typography,
  Button,
  Skeleton,
  Card,
  CardContent,
} from "@mui/material";
import { Carousel } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Recommendations from "../RecommendationsCard";
import "./style.scss";
import { getAccountInfo } from "../../../redux/actions/settings";
import robotIcon from "../../../assets/img/robot-icon.svg";
import noRecommendationImage from "../../../assets/img/noRecommendationImage.svg";
import CardMedia from "@mui/material/CardMedia";

// other imports

const CustomerDetaiilTab = () => {
  const dispatch = useDispatch();
  const { insightsData, isLoading } = useSelector((state) => state.insights);
  const { recommendationData } = useSelector((state) => state.dashboard);

  const [recommendData, setRecommendData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = Array.from(new Array(8)); // Mock data

  // Treat screens up to 900px as mobile
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 2570 });
  const isWideDesktop = useMediaQuery({ minWidth: 2560 });

  useEffect(() => {
    if (recommendationData) {
      setRecommendData(recommendationData);
    }
  }, [recommendationData]);

  // useEffect(() => {
  //   dispatch(getAccountInfo());
  // }, [dispatch]);

  // Responsive Items per Slide
  const getItemsPerSlide = () => {
    return isMobile ? 2 : 4; // 2 items on mobile, 4 on larger screens
  };

  return (
    <Box style={{ marginBottom: "20px" }}>
      <Grid
        container
        spacing={3}
        style={isMobile ? { maxWidth: "100%", margin: "0px" } : {}}
      >
        <Grid
          item
          xl={9.5}
          lg={8}
          md={8}
          xs={12}
          sx={{
            paddingRight: isMobile ? "12px" : "0",
            marginLeft: isMobile ? "4px" : "0",
          }}
        >
          <div
            style={{
              paddingLeft: isMobile ? "0px" : "0px",
              height: "100%",
              maxHeight: isMobile
                ? "100%"
                : isWideDesktop
                ? "100%"
                : isDesktop
                ? "100%"
                : "700px",
              paddingRight: isMobile ? "0px" : "0px",
              maxWidth: "-webkit-fill-available",
              marginRight: isMobile ? "0px" : "0px",
            }}
          >
            <BioCard
              name={insightsData?.segmentBio?.name}
              image={insightsData?.segmentBio?.img}
              rating={insightsData?.segmentBio?.rating}
              isLoading={isLoading}
            />
          </div>
        </Grid>

        {!isMobile && (
          <Grid item xl={2.5} lg={4} md={4} xs={8}>
            <div
              style={{
                borderRadius: "10px",
                backgroundColor: "#FFFFFF",
                height: isWideDesktop ? "758px" : isDesktop ? "713px" : "700px",
              }}
            >
              <FeedCard
                name={insightsData?.segmentBio?.name}
                image={insightsData?.segmentBio?.img}
                rating={insightsData?.segmentBio?.rating}
                isLoading={isLoading}
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div>
            <Box
              className="segmentBio"
              sx={{
                padding: "10px",
                backgroundColor: "white",
                borderRadius: "10px",
                paddingBottom: "47px",
                marginLeft: isMobile ? "5px" : "",
                marginRight: isMobile ? "12px" : "",
              }}
            >
              <Grid
                container
                style={{
                  backgroundColor: !isMobile ? "white" : "white",
                  padding: "1%",
                  marginBottom: "14px",
                }}
              >
                <Grid xs={12}>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginLeft: "8px",
                    }}
                  >
                    <span
                      className="blue bold-600"
                      style={{
                        fontSize: isMobile ? "1.05rem" : "16px",
                        marginLeft: isMobile ? "" : "20px",
                      }}
                    >
                      Recommendations
                    </span>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgb(177 177 177 / 46%)",
                        borderRadius: "5px",
                        padding: "5px 10px",
                        color: "#6f7174",
                        fontWeight: "500",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      <img
                        src={robotIcon}
                        alt="Robot Icon"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                      />
                      AI-Enabled
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: isMobile ? "0px" : "28px",
                }}
              >
                <Grid item xs={12} spacing={2}>
                  {/* <Box
                sx={{
                  margin: "0 auto",
                  paddingRight: "20px",
                  paddingLeft: "25px",
                }}
              >
                <Grid container spacing={1}> */}
                  {recommendData.length === 0 ? (
                    <Grid item xs={12} justifyContent="center">
                      <Box
                        className="customer-insights sliderBox customeCarousel"
                        sx={{ marginBottom: "15px" }}
                      >
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {items
                            .slice(
                              currentIndex,
                              currentIndex + getItemsPerSlide()
                            )
                            .map((_, index) => (
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={3}
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Card
                                  style={{
                                    borderRadius: "10px",
                                    maxWidth: "500px",
                                    width: isMobile ? "93%" : "91%",
                                    paddingBottom: "20px",
                                    height: "auto",
                                    // padding: "5px",
                                    // margin: "1px",
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    sx={{
                                      objectFit: "fill",
                                      padding: "10px",
                                      width: "90%",
                                      margin: "auto",
                                    }}
                                    alt="Auto Detailing"
                                    height="140"
                                    image={noRecommendationImage}
                                  />
                                  <CardContent sx={{ textAlign: "center" }}>
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Skeleton
                                        variant="rectangular"
                                        width={110}
                                        height={20}
                                        sx={{
                                          borderRadius: "10px",
                                          marginTop: "-5px",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 1,
                                      }}
                                    >
                                      <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={50}
                                        sx={{
                                          borderRadius: "16px",
                                        }}
                                      />
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      mt={2}
                                    >
                                      <Skeleton
                                        variant="rounded"
                                        width={60}
                                        height={20}
                                        sx={{
                                          borderRadius: "10px",
                                          marginRight: 2,
                                        }}
                                      />
                                      <Button
                                        sx={{
                                          borderRadius: "8px",
                                          fontSize: "10px",
                                          marginLeft: "28%",
                                          padding: isMobile
                                            ? "5px 5px"
                                            : "4% 16%",
                                        }}
                                        variant="contained"
                                        color="primary"
                                      >
                                        Recommend
                                      </Button>
                                    </Box>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Grid>
                  ) : (
                    <Carousel
                      infinite={false}
                      slidesToShow={getItemsPerSlide()}
                      slidesToScroll={getItemsPerSlide()}
                      dots={true}
                      arrows={false}
                      afterChange={(current) => setCurrentIndex(current)}
                      customPaging={(index) => (
                        <svg
                          style={{
                            marginTop: isMobile ? "40px" : "45px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          width="10"
                          height="10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="5"
                            cy="5"
                            r="5"
                            fill={index === currentIndex ? "#002857" : "white"}
                            stroke="#002857"
                          />
                        </svg>
                      )}
                    >
                      {recommendData.map((card, index) => (
                        <div key={index}>
                          <Recommendations
                            name={card.recommendation}
                            image={
                              card.image ? card.image : noRecommendationImage
                            }
                            description={card.description}
                            price={card.price}
                            isLoading={false}
                          />
                        </div>
                      ))}
                    </Carousel>
                  )}
                  {/* </Grid>
              </Box> */}
                </Grid>
              </Grid>
            </Box>
            <RecommendationsWrapper />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerDetaiilTab;
