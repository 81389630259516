import { React, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Box, Typography, ButtonGroup, Button } from "@mui/material";
import PlanCards from "./PlanCards";
import PlanTable from "./PlanTable";
import "./style.scss";
import { scrollToTop } from "../../../utils/utilities";
import { useDispatch } from "react-redux";
import { getProductList } from "../../../redux/actions/onboarding";

const ChoosePlan = () => {
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const dispatch = useDispatch();
  const [isMonthly, setIsMonthly] = useState(true);
  useEffect(() => {
    dispatch(getProductList("Month"));
    scrollToTop();
  }, []);
  useEffect(() => {
    dispatch(getProductList(!isMonthly? "Year": "Month"));
    scrollToTop();
  }, [isMonthly]);
  
  return (
    <Box className="choosePlan">
      {!isMobile && (
        <>
          <Typography variant="h6" className="heading" style={{textAlign:'left'}}>
            Choose A Plan
          </Typography>
          <Typography variant="body1" className="subHeading" style={{fontFamily:'Gotham'}}>
            Plans right-sized for your business
          </Typography>
        </>
      )}
      <Box className="switchBox">
        <ButtonGroup color="secondary">
          <Button
            variant={isMonthly ? `contained` : `outlined`}
            sx={{
              color: `${isMonthly && `white`}`,
              padding: "10px",
              width: "100px",
            }}
            onClick={() => setIsMonthly(true)}
          >
            Monthly
          </Button>
          <Button
            variant={!isMonthly ? `contained` : `outlined`}
            sx={{
              color: `${!isMonthly && `white`}`,
              padding: "10px",
              width: "100px",
            }}
            onClick={() => setIsMonthly(false)}
          >
            Annually
          </Button>
        </ButtonGroup>
      </Box>
      <PlanCards isMonthly={isMonthly} />
      <PlanTable />
    </Box>
  );
};

export default ChoosePlan;
