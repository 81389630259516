/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { getAccountInfo } from "../../redux/actions/settings";

import Footer from "../../components/Layout/Footer";
import CheckInLogo from "../../assets/img/check-in-logo.svg";

import "./style.scss";
import { Col, Form, Input, Row, Spin, Button, Checkbox } from "antd";
import { createCheckIn } from "../../redux/actions/check-in";

const regexPhone = new RegExp(
  "^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$"
);

const CheckIn = () => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 852 });
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { accountInfo } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(getAccountInfo());
  }, []);

  const { loading, error, checkin } = useSelector((state) => state.checkIn);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    acceptedTerms: false,
    allowedShareData: false,
  });

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    acceptedTerms,
    allowedShareData,
    
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onCheckBoxesChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.checked });

  useEffect(() => {
    if (checkin) {
      form.resetFields();
    }

    return () => {
      form.resetFields();
    };
  }, [checkin]);

  const handleSubmit = (values) => {
    // Retrieve the user_Id from local storage
    const user_Id = localStorage.getItem("userIDSub"); 
  
    // Add the user_Id to the values object
    const submissionData = {
      ...values,
      user_Id: user_Id || "",
      company_legal_name: accountInfo?.company_legal_name||"",
    };
  
    if (!submissionData.allowedShareData) {
      submissionData.allowedShareData = false;
    }
  
    // Dispatch the action with all the data, including user_Id
    dispatch(createCheckIn(submissionData, token));
  
    // Reset the form state
    setFormData({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      acceptedTerms: false,
      allowedShareData: false,
      user_Id: "",
      company_legal_name:"",
    });
  
    form.resetFields();
    console.log("Submitted user_Id:", user_Id);
    console.log("Submitted company_legal_name:", accountInfo?.company_legal_name);
  };
  
  return (
    // <Spin tip="Signing..." size="large">
    <div className="checkIn">
      <div
        className={`checkIn-main${isMobile ? " mobile" : ""}${
          isTablet ? " tablet" : ""
        }`}
      >
        <div className="checkIn-main-left">
          <div className="checkIn-main-left-header">
            <div className="checkIn-main-left-header-logo">
              <img src={CheckInLogo} alt="Logo" />
            </div>
            <h1 className="checkIn-main-left-header-title" style={{paddingTop:'45px'}}>Check in</h1>
            <p className="checkIn-main-left-header-subtitle" >
              Please enter your information below
            </p>
          </div>
          <div className="checkIn-main-left-main" >
          <Form
    form={form}
    layout="vertical"
    className="checkIn-form"
    onFinish={handleSubmit}
>
              <Row gutter={[24, 0]}>
                <Col style={{marginBottom:'20px'}} xl={12} md={12} sm={24} xs={24}>
                <Form.Item
      name="firstName"
      label={<span>First name</span>}
    >
      <Input
        className="input-autofill" // Add the class here
        style={{ marginTop: '3px' }}
        placeholder="John"
        size="large"
        value={firstName}
       
      />
    </Form.Item>

                  {/* <div className="input-group">
                    <label className="form-label" htmlFor="firstName">
                      First name
                    </label>
                    <input
                      className="form-input"
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="John"
                      value={firstName}
                      onChange={onChange}
                      required
                    />
                  </div> */}
                </Col>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form.Item name="lastName" label="Last name">
                    <Input className="input-autofill" style={{marginTop:'3px'}} placeholder="Doe" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone number"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Enter your phone number",
                      },
                      {
                        pattern:
                          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
                        message: "Phone number invalid",
                      },
                      {
                        min: 10,
                        message: "Enter 10 digits",
                      },
                      {
                        max: 12,
                        message: "Max 12 digits",
                      },
                    ]}
                  >
                    <Input
                    style={{marginTop:'3px' ,backgroundColor:  'white'}}
                      placeholder="999-999-9999"
                      size="large"
                    />
                    {/* <PhoneInputFormItem
                      name="phone"
                      label="Phone number"
                      errorMessage="Please provide a valid phone number"
                      hasFeedback
                    >
                      <PhoneInput />
                    </PhoneInputFormItem> */}
                  </Form.Item>

                  {/* <div className="input-group">
                    <label className="form-label" htmlFor="phoneNumber">
                      Phone number
                    </label>
                    <input
                      className="form-input"
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="Enter 10 digits, no lines"
                      value={phoneNumber}
                      onChange={onChange}
                      // required
                    />
                  </div> */}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                <Form.Item
      name="email"
      label="Email"
      hasFeedback
      rules={[
        {
          required: true,
          message: "Enter your email",
        },
        {
          type: "email",
          message: "Enter a valid email address",
        },
      ]}
    >
      <Input  className="input-autofill"
        style={{marginTop:'3px' }}
        placeholder="name@company.com"
        size="large"
      />
    </Form.Item>

                  {/* <div className="input-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="form-input"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="email"
                      value={email}
                      onChange={onChange}
                      required
                    />
                  </div> */}
                </Col>

                <Col span={24}>
                <Form.Item
  name="acceptedTerms"
  valuePropName="checked"
  hasFeedback
  rules={[
    {
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject(new Error("Please agree before continuing")),
    },
  ]}
>
  <div style={{ display: "flex", alignItems: "baseline" }}>
    <Checkbox
      className=""
      style={{ marginRight: "10px", marginBottom:"4px"}}
    />
    <span
      style={{
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "17px",
        lineHeight: "1.5",
        color: "#002857",
      }}
    >
      Accept{" "}
      <a href="#" id="acceptedTerms">
        terms and conditions
      </a>
    </span>
  </div>
</Form.Item>

                  <Form.Item
                    name="allowedShareData"
                    valuePropName="checked"
              
                  >  
<div style={{ display: "flex", alignItems: "baseline" }}>
  <Checkbox
    className=""
    style={{ marginRight: "10px", }} // Adjust marginTop as needed
  />
  <span
    style={{
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "1.5",
      color: "#002857",
      marginBottom:"1%"
    }}
  >
    Agree to share data with partners for improved{" "}
    <a href="#" id="allowedShareData">
      customer experience and personalized offers.
    </a>
  </span>
</div>


                  </Form.Item>
                </Col>

                <Col span={24}></Col>
              </Row>

              {/* <Row>
                <Col span={24}>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="acceptedTerms"
                      name="acceptedTerms"
                      checked={acceptedTerms}
                      onChange={onCheckBoxesChange}
                      required
                    />
                    <label
                      className="input-checkbox-label"
                      htmlFor="acceptedTerms"
                    >
                      Accept{" "}
                      <a href="#" id="acceptedTerms">
                        terms and conditions
                      </a>
                    </label>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="input-checkbox">
                    <input
                      type="checkbox"
                      id="allowedShareData"
                      name="allowedShareData"
                      checked={allowedShareData}
                      onChange={onCheckBoxesChange}
                    />
                    <label
                      className="input-checkbox-label"
                      htmlFor="allowedShareData"
                    >
                      Agree to share data with partners for improved{" "}
                      <a className="">
                        customer experience and personalized offers.
                      </a>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col span={24}>
                  {/* <button type="submit" className="checkInBtn">
                    Check-in
                  </button> */}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="checkInBtn"
                    block
                    loading={loading}
                  >
                    Check-in
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="checkIn-main-right">
          <div className="checkIn-main-right-overide"></div>
        </div>
      </div>
      <Footer />
    </div>
    // </Spin>
  );
};

export default CheckIn;

//  Your username is Kata1 and temporary password is 8ENr6fD?.
