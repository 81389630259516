import axios from "axios";
import {
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_ERROR,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ACCOUNT_INFO_ERROR,
  UPDATE_ACCOUNT_INFO_ORG,
  UPDATE_ACCOUNT_INFO_ORG_ERROR,
  INVITE_USER,
  INVITE_USER_ERROR,
  COUNT_USERS_SUCCESS,
  COUNT_USERS_ERROR,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_USERS_INFO_SUCCESS,
  GET_USERS_INFO_ERROR,
  SUBMIT_USER_INFO_SUCCESS,
  SUBMIT_USER_INFO_ERROR,
  DELETE_USER_BY_ID,
  DELETE_USER_BY_ID_ERROR,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_ERROR,
} from "../constant/settings";

export const getAccountInfo = () => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });
    let email = localStorage.getItem("email");
    let tenantID = localStorage.getItem("tenantID");
    let userIdSub = localStorage.getItem("userIDSub");

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/get-account-information`,
      {
        params: {
          email: email,
        },
        headers: {
          tenantId: tenantID,
          userIdSub: userIdSub,
        },
      }
    );
    dispatch({ type: GET_ACCOUNT_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ACCOUNT_INFO_ERROR, payload: error });
  }
};

export const updateAccountInfo = (body) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/update-account-information`,
      body,
      {
        headers: {
          tenantId: tenantID,
          userIdSub: userIdSub,
        },
      }
    );

    dispatch({ type: UPDATE_ACCOUNT_INFO, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_ACCOUNT_INFO_ERROR, payload: error });
  }
};

export const updateAccountOrgInfo = (body) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/update-organization-information`,
      body,
      {
        headers: {
          tenantId: tenantID,
          userIdSub: userIdSub,
        },
      }
    );

    dispatch({ type: UPDATE_ACCOUNT_INFO_ORG, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_ACCOUNT_INFO_ORG_ERROR, payload: error });
  }
};

export const inviteUserSES = (email, role) => async (dispatch) => {
  try {
    let tenantID = localStorage.getItem("tenantID");

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/invite-user`,
      {
        email: email,
        tenant_id: tenantID,
        roles: role,
      }
    );

    dispatch({ type: INVITE_USER, payload: data });
    dispatch(getAllUsersInfo());
  } catch (error) {
    dispatch({ type: INVITE_USER_ERROR, payload: error });
  }
};

export const countUsers = () => async (dispatch) => {
  try {
    let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/count`,
      {
        params: {
          tenant_id: tenantID,
        },
      }
    );
    dispatch({ type: COUNT_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COUNT_USERS_ERROR, payload: error });
  }
};
export const getAllUsersInfo = () => async (dispatch) => {
  try {
    let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/all-users`,
      {
        params: {
          tenant_id: tenantID,
        },
      }
    );
    dispatch({ type: GET_ALL_USERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_USERS_ERROR, payload: error });
  }
};

export const getUsersInfobyId = (userId) => async (dispatch) => {
  try {
    // let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/particular-user-info`,
      {
        params: {
          user_id: userId,
        },
      }
    );

    dispatch({ type: GET_USERS_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USERS_INFO_ERROR, payload: error });
  }
};

export const submitUserInfo = (body) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/update-user-info`,
      body
    );
    dispatch(getAllUsersInfo());
    dispatch({ type: SUBMIT_USER_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SUBMIT_USER_INFO_ERROR, payload: error });
  }
};

export const deleteUserById = (userId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/delete-info`,
      {
        params: {
          user_id: userId,
        },
      }
    );
    dispatch(getAllUsersInfo());
    dispatch(countUsers());
    dispatch({ type: DELETE_USER_BY_ID, payload: data });
  } catch (error) {
    dispatch({ type: DELETE_USER_BY_ID_ERROR, payload: error });
  }
};

export const resendInvite = (email) => async (dispatch) => {
  try {
    // await dispatch({ type: GET_USER_RATE });

    let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/user/resend-invite`,
      {
        email: email,
        tenant_id: tenantID,
      }
    );

    dispatch({ type: RESEND_INVITE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RESEND_INVITE_ERROR, payload: error });
  }
};
