import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Snackbar,
  Alert,
  Select,
  TextField,
  MenuItem,
  Dialog,
  IconButton,
  FormControl,
  FilledInput,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./style.scss"; // Assuming custom styling here
import Labels from "./label"; // Imported label values
import { useMediaQuery} from "react-responsive";
const InfoDialog = ({ open, onClose, title, description }) => (
  <Box>
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 3, // Adjust the border radius here
          paddingTop:"40px",
          paddingBottom:"60px",
          paddingLeft:"50px",
          paddingRight:"50px",
          width: "375px",
          marginRight:"9%",
          marginBottom:"8%",
        },
      }}
    >
      {" "}
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "10px", // Adjust the top position
          right: "10px", // Adjust the left position
          color: "#000", // Color of the icon
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box>
        <Typography
          style={{fontWeight:"400", fontSize: "14px", color: "#02AFEF" }}
          
          gutterBottom
        >
          Metric
        </Typography>
        <Typography
          style={{marginBottom:"16px",fontSize: "16px", color: "#002857" }}
          variant="h6"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Dialog>
  </Box>
);

const SettingMetrics = () => {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogContent, setInfoDialogContent] = useState({
    title: "",
    description: "",
  });

  // State for input fields
  const [customerAcquisitionCost, setCustomerAcquisitionCost] = useState("");
  const [powerBuyerPercentage, setPowerBuyerPercentage] = useState(""); // Single value selector for Power Buyer Percentages
  const [limitedBuyerPercentage, setLimitedBuyerPercentage] = useState(""); // Single value selector for Limited Buyer Percentages
  const [saveSuccess, setSaveSuccess] = useState(false); // To show success message
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const handleSave = () => {
    // Add logic here to save the metrics (e.g., API call to your backend)
    setSaveSuccess(true);
  };

  const handleCloseSnackbar = () => {
    setSaveSuccess(false);
  };

  const commonInputStyles = {
    width:isMobile?"100%":"90%",
    height: "2.8rem",
    borderRadius: "10px",
    fontSize: "12px",
  };

  const handleOpenInfoDialog = (title, description) => {
    setInfoDialogContent({ title, description });
    setInfoDialogOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setInfoDialogOpen(false);
  };
  
  return (
    <><h1 className="title" style={{ display: isMobile ? "contents" : "none", marginLeft: isMobile ? "20px" : "" }}>
      Metrics
    </h1><div style={{ alignItems: isMobile ? 'left' : 'normal', marginLeft: isMobile ? '0%' : '-1%' }}>
        <Box pt={4} className="settings-metrics-container">
          {/* Customer Acquisition Cost Field */}
          <Box
            mt={2}
            mb={2}
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >

            <Typography
              style={{
                fontSize: isMobile ? "13px" : "14px",
                display: "inline-flex",
                alignItems:"flex-start", 
                color: "#002857"
              }}
            >
              What is your average customer acquisition cost?
              <IconButton
  sx={{
    color: "white",
    backgroundColor: "#002857",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    marginLeft: "5px",
    cursor: "pointer",
    verticalAlign: "middle",
    "&:hover": {
      backgroundColor: "#002857", // Same as default background
      color: "white",             // Same as default color
    },
  }}
  onClick={() =>
    handleOpenInfoDialog(
      Labels.CUSTOMER_ACQUISITION_COST,
      Labels.CUSTOMER_ACQUISITION_COST_DESC
    )
  }
>
  <InfoOutlinedIcon />
</IconButton>

            </Typography>

            <TextField
              style={commonInputStyles}
              variant="outlined"
              value={customerAcquisitionCost}
              onChange={(e) => setCustomerAcquisitionCost(e.target.value)}
              placeholder="$"
              InputProps={{
                sx: {
                  ...commonInputStyles,
                  marginTop: "10px",
                  color: "#707070",
                },
              }} />
          </Box>

          {/* Power Buyer Percentages */}
          <Grid
            item xs={12} md={6}
          >
            <Box
              mt={5}
              mb={5}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography
                style={{
                  fontSize: isMobile ? "13px" : "14px",
                  display:isMobile?"":"inline-flex",
                  alignItems:"flex-start", 
                  marginBottom: "10px",
                  color: "#002857"
                }}
              >
                Set your “Power Buyer” rank by selecting your preferred LTV
                percentile.

                <IconButton
                  sx={{
                    color: "white",
                    backgroundColor: "#002857",
                    borderRadius: "50%",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    width: "18px",
                    height: "18px",
                    marginLeft: "5px",
                    cursor: "pointer",
                    verticalAlign: "middle",
                    marginBottom:isMobile?"4px":"",
                    "&:hover": {
                      backgroundColor: "#002857", // Same as default background
                      color: "white",             // Same as default color
                    },
                  }}
                  onClick={() => handleOpenInfoDialog(
                    Labels.POWER_BUYER_PERCENTAGE,
                    Labels.POWER_BUYER_PERCENTAGE_DESC
                  )}
                >
                  <InfoOutlinedIcon />
                </IconButton>

              </Typography>

              <FormControl style={commonInputStyles} variant="outlined">
                <Select

                  style={commonInputStyles}
                  value={powerBuyerPercentage}
                  onChange={(e) => setPowerBuyerPercentage(e.target.value)}
                  displayEmpty
                  
                  inputProps={{
                    sx: {
                      ...commonInputStyles,
                      opacity: powerBuyerPercentage ? 0.8 : 2, // Set opacity based on selection

                    },
                  }}
                 
                  sx={{
                    '& .MuiSelect-select': {
                      opacity: powerBuyerPercentage ? 0.8 : 2, // Set opacity for the selected value
                      paddingLeft:isMobile?"10px":"",
                    },
                    '& .MuiSelect-icon': {
                     color: '#002857', 
                     height:"40px",
                     width:"40px",
                      marginTop:"-8px"
      },
                  }}
                >
                  <MenuItem value="">{/* Empty value */}</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={5}>5%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={10}>10%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={20}>20%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={30}>30%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={40}>40%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={50}>50% and below</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {/* Limited Buyer Percentages */}
          <Grid
            item xs={12} md={6}
          >
            <Box
              mt={2}
              mb={2}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography
                style={{
                  fontSize: isMobile ? "13px" : "14px",
                  display:isMobile?"":"inline-flex",
                  alignItems:isMobile?"":"flex-start", 
                  marginBottom:"10px",
                  color: "#002857",
                }}
              >
                Set your “Limited Buyer” rank by selecting your preferred LTV percentile.

                <IconButton
                  sx={{
                    color: "white",
                    backgroundColor: "#002857",
                    borderRadius: "50%",
                    // display: "flex",  
                    // alignItems: "center",
                    // justifyContent: "center",
                    width: "18px",
                    height: "18px",
                    marginLeft: "5px",  
                    cursor: "pointer",
                    verticalAlign: "middle",
                    marginBottom:isMobile?"4px":"",
                    "&:hover": {
                      backgroundColor: "#002857", // Same as default background
                      color: "white",             // Same as default color
                    },
                  }}
                  onClick={() => handleOpenInfoDialog(
                    Labels.LIMITED_BUYER_PERCENTAGE,
                    Labels.LIMITED_BUYER_PERCENTAGE_DESC
                  )}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Typography>



              <FormControl style={commonInputStyles} variant="outlined">
                <Select
                  style={commonInputStyles}
                  value={limitedBuyerPercentage}
                  onChange={(e) => setLimitedBuyerPercentage(e.target.value)}
                  displayEmpty
                  inputProps={{
                    sx: {
                      ...commonInputStyles,
                      opacity: limitedBuyerPercentage ? 0.60 : 1, // Set opacity based on selection
                    },
                  }}
                 
                  sx={{
                    '& .MuiSelect-select': {
                      opacity: limitedBuyerPercentage ? 0.8 : 2, // Set opacity for the selected value
                      paddingLeft:isMobile?"10px":"",
                    },
                    '& .MuiSelect-icon': {
                      color: '#002857', // Change the dropdown icon color here
                      height:"40px",
                      width:"40px",
                      marginTop:"-8px"
       },
                  }}
                >
                  <MenuItem value="">{/* Empty value */}</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={5}>5%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={10}>10%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={20}>20%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={30}>30%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={40}>40%</MenuItem>
                  <MenuItem style={{ color: "#707070" }} value={50}>50% and below</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          {/* Save Button */}
          <Box style={{ display: "flex" }} mt={4} mb={2}>
            <Button
              style={{
                paddingRight: "50px",
                paddingLeft: "50px",
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                marginTop: "40px",
                fontWeight: "700",
                borderRadius: "30px",
              }}
              variant="contained"
              color="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>

          {/* Snackbar for Success Confirmation */}
          <Snackbar
            open={saveSuccess}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="success">
              Save successful. It may take up to 24 hours for this change to take
              effect.
            </Alert>
          </Snackbar>

          {/* Info Dialog */}
          <InfoDialog
            open={infoDialogOpen}
            onClose={handleCloseInfoDialog}
            title={infoDialogContent.title}
            description={infoDialogContent.description} />
        </Box>
      </div></>
  );
};

export default SettingMetrics;
