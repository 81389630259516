import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  jobFunctionList,
  accountRoleList,
  databilityUseOptionsList,
} from "../../../pages/Onboarding/constants.js";
import {
  cleanPhoneNumber,
  formatPhoneNumber,
} from "../../../utils/utilities.js";
import CancelModal from "../CancelModal/index.jsx";
import "./style.scss";
import { setBasicInfo } from "../../../redux/actions/onboarding.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const BasicInformation = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { onboarding } = useSelector((state) => state.onboarding);
  const [userRole, setUserRole] = useState("");

  const [openStates, setOpenStates] = useState({
    jobFunction: false,
    accountRole: false,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleOpen = (id) => setOpenStates((prev) => ({ ...prev, [id]: true }));
  const handleClose = (id) =>
    setOpenStates((prev) => ({ ...prev, [id]: false }));

  const RotatingArrowIcon = ({ open }) => (
    <ArrowDropDownIcon
      style={{
        transform: open ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease",
        marginRight: "4px",
      }}
    />
  );
  useEffect(() => {
    setUserRole(localStorage.getItem("onboardingrole"));
  }, [onboarding]);

  const handleCheckBoxChange = (e, option) => {
    const { checked } = e.target;
    const updatedDataUsage = checked
      ? [...formik.values.dataUsage, option.value]
      : formik.values.dataUsage.filter((value) => value !== option.value);
    formik.setFieldValue("dataUsage", updatedDataUsage);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    jobRole: "",
    jobFunction: "",
    accountRole: "",
    dataUsage: [],
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("Please enter your First name")
      .matches(/\S/, "Invalid First name"),
    lastName: yup
      .string()
      .required("Please enter your Last name")
      .matches(/\S/, "Invalid Last name"),
    phone: yup
      .string()
      .required("Please enter phone number")
      .min(14, "Phone must be at least 10 characters")
      .matches(/\S/, "Invalid phone"),
    jobRole: yup
      .string()
      .required("Please enter your Job Role")
      .matches(/\S/, "Invalid Job Role"),
    jobFunction: yup.string().required("Please select an option"),
    // accountRole: yup.string().required("Please select an option"),
    dataUsage: yup
      .array()
      .of(yup.string())
      .min(1, "At least one option must be selected")
      .required("At least one option must be selected"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        setBasicInfo({
          email: onboarding.account.email,
          first_name: values.firstName,
          last_name: values.lastName,
          phone: cleanPhoneNumber(values.phone),
          job_role: values.jobRole,
          job_function: values.jobFunction,
          databillity_choices: values.dataUsage.join(", "),
          account_level_role: userRole === "User" ? "User" : values.accountRole,
        })
      );
    },
  });

  useEffect(() => {
    if (onboarding.basicInfo) {
      formik.setFieldValue("firstName", onboarding.basicInfo.first_name);
      formik.setFieldValue("lastName", onboarding.basicInfo.last_name);
      formik.setFieldValue(
        "phone",
        formatPhoneNumber(onboarding.basicInfo.phone)
      );
      formik.setFieldValue("jobRole", onboarding.basicInfo.job_role);
      formik.setFieldValue("jobFunction", onboarding.basicInfo.job_function);
      formik.setFieldValue(
        "accountRole",
        onboarding.basicInfo.account_level_role
      );
    }
    if (onboarding.basicInfo?.databillity_choices) {
      const dataUsageArray = onboarding.basicInfo.databillity_choices
        .split(",")
        .map((item) => item.trim());
      formik.setFieldValue("dataUsage", dataUsageArray);
    }
  }, [onboarding]);

  return (
    <Box
      className="infoForm"
      style={{ minHeight: isMobile ? "93%" : "inherit" }}
    >
      <Typography
        variant="h6"
        style={{
          fontSize: isMobile ? "16px" : "20px",
          textAlign: "left",
          marginBottom: isMobile ? "3px" : "10px",
        }}
        className="heading"
      >
        Basic Information
      </Typography>
      <Typography
        variant="body1"
        className="subHeading"
        style={{
          fontSize: isMobile ? "12px" : "16px",
          marginBottom: isMobile ? "40px" : "60px",
        }}
      >
        Please provide all the information below
      </Typography>
      <FormikProvider value={formik}>
        <Grid
          container
          spacing={3}
          style={{ marginBottom: 0, paddingBottom: 0 }}
        >
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography
                style={{ fontSize: isMobile ? "12px" : "18px" }}
                variant="body1"
                className="formHead"
              >
                First name
              </Typography>
              <TextField
                placeholder="Jane"
                variant="outlined"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.firstName && formik.errors.firstName
                )}
                helperText={formik.touched.firstName && formik.errors.firstName}
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: isMobile ? "10px" : "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D",
                  },
                  // Styling the placeholder
                  "&::placeholder": {
                    color: "#C8C8CE",
                    fontWeight: "bold",
                    fontSize: isMobile ? "10px" : "16px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead"
                style={{ fontSize: isMobile ? "12px" : "18px" }}
              >
                Last name
              </Typography>
              <TextField
                placeholder="Doe"
                variant="outlined"
                name="lastName"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: isMobile ? "10px" : "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D",
                  },
                  // Styling the placeholder
                  "&::placeholder": {
                    color: "#C8C8CE",
                    fontWeight: "bold",
                    fontSize: isMobile ? "10px" : "16px",
                  },
                }}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.lastName && formik.errors.lastName
                )}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead mobileField"
                style={{
                  fontSize: isMobile ? "12px" : "18px",
                }}
              >
                Phone
              </Typography>
              <TextField
                placeholder="999-999-9999"
                variant="outlined"
                name="phone"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: isMobile ? "10px" : "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D",
                  },
                  // Styling the placeholder
                  "&::placeholder": {
                    color: "#C8C8CE",
                    fontWeight: "bold",
                    fontSize: isMobile ? "10px" : "16px",
                  },
                }}
                value={formik.values.phone}
                onChange={(e) =>
                  formik.setFieldValue(
                    "phone",
                    formatPhoneNumber(e.target.value)
                  )
                }
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead"
                style={{
                  fontSize: isMobile ? "12px" : "18px",
                }}
              >
                What is your job title?
              </Typography>
              <TextField
                variant="outlined"
                sx={{
                  // Styling the input text
                  input: {
                    color: "#4A4C4D", // Input text color
                    fontSize: isMobile ? "10px" : "16px", // Adjusting the font size
                    fontWeight: "500",
                    WebkitTextFillColor: "#4A4C4D",
                  },
                  // Styling the placeholder
                  "&::placeholder": {
                    color: "#C8C8CE",
                    fontWeight: "bold",
                    fontSize: isMobile ? "10px" : "16px",
                  },
                }}
                placeholder="ex. Director of Marketing"
                name="jobRole"
                value={formik.values.jobRole}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.jobRole && formik.errors.jobRole)}
                helperText={formik.touched.jobRole && formik.errors.jobRole}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead"
                style={{ fontSize: isMobile ? "12px" : "18px" }}
              >
                What is your job function?
              </Typography>
              <FormControl>
                <InputLabel shrink={false}>
                  {formik.values.jobFunction ? "" : "Select"}
                </InputLabel>
                <Select
                  open={openStates.jobFunction}
                  onOpen={() => handleOpen("jobFunction")}
                  onClose={() => handleClose("jobFunction")}
                  IconComponent={(props) => (
                    <RotatingArrowIcon
                      open={openStates.jobFunction}
                      {...props}
                    />
                  )}
                  name="jobFunction"
                  sx={{
                    // Styling the selected text in the Select field
                    "& .MuiSelect-select": {
                      color: "#4A4C4D", // Selected text color
                      fontSize: isMobile ? "10px" : "16px", // Adjusting the font size
                      fontWeight: "500", // Font weight
                    },
                    // Styling the dropdown menu items
                    "& .MuiMenuItem-root": {
                      color: "#4A4C4D", // Menu item text color
                      fontSize: "16px",
                      fontWeight: "500",
                    },
                    // Styling the dropdown icon (the arrow)
                    "& .MuiSvgIcon-root": {
                      color: "#4A4C4D", // Icon color
                    },
                  }}
                  value={formik.values.jobFunction}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.jobFunction && formik.errors.jobFunction
                  )}
                >
                  {jobFunctionList.map((job, index) => (
                    <MenuItem
                      key={index}
                      value={job}
                      sx={{ color: "#6F7174", fontWeight: "medium" }}
                    >
                      {job}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.jobFunction && (
                <Typography variant="caption" color="error">
                  {formik.touched.jobFunction && formik.errors.jobFunction}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="formItem">
              <Typography
                variant="body1"
                className="formHead"
                style={{
                  fontSize: isMobile ? "12px" : "18px",
                  marginBottom: isMobile ? "23px" : "",
                }}
              >
                How do you plan on using DataBillity? (Check all that apply)
              </Typography>
              <FormGroup>
                <Grid container spacing={2}>
                  {databilityUseOptionsList.map((option, index) => (
                    <Grid
                      item
                      xs={6}
                      key={index}
                      style={{ paddingTop: "20px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="dataUsage"
                            checked={formik.values.dataUsage.includes(
                              option.value
                            )}
                            onChange={(event) =>
                              handleCheckBoxChange(event, option)
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            className="formCheckbox"
                            style={{ fontSize: isMobile ? "10px" : "16px" }}
                          >
                            {option.label}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              {formik.errors.dataUsage && (
                <Typography variant="caption" color="error">
                  {formik.touched.dataUsage && formik.errors.dataUsage}
                </Typography>
              )}
            </Box>
          </Grid>
          {userRole !== "User" && userRole !== "Admin" && (
            <Grid item xs={12}>
              <Box className="formItem">
                <Typography variant="body1" className="formHead">
                  account level role?
                </Typography>
                <FormControl>
                  <InputLabel shrink={false}>
                    {formik.values.accountRole ? "" : "Select"}
                  </InputLabel>
                  <Select
                    open={openStates.accountRole}
                    onOpen={() => handleOpen("accountRole")}
                    onClose={() => handleClose("accountRole")}
                    IconComponent={(props) => (
                      <RotatingArrowIcon
                        open={openStates.accountRole}
                        {...props}
                      />
                    )}
                    name="accountRole"
                    sx={{
                      input: {
                        fontWeight: "normal", // Removes boldface
                      },
                    }}
                    value={formik.values.accountRole}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.accountRole && formik.errors.accountRole
                    )}
                  >
                    {accountRoleList.map((role, index) => (
                      <MenuItem
                        key={index}
                        value={role}
                        sx={{ color: "#6F7174", fontWeight: "medium" }}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.errors.accountRole && (
                  <Typography variant="caption" color="error">
                    {formik.touched.accountRole && formik.errors.accountRole}
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box className="formButtons">
              <Button
                variant="outlined"
                disabled
                style={{
                  marginLeft: isMobile ? "0px" : "",
                  width: isMobile ? "150px" : "200px",
                  borderColor: "#002857",
                  color: "#002857",
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={formik.handleSubmit}
                style={{
                  marginLeft: isMobile ? "0px" : "",
                  width: isMobile ? "150px" : "200px",
                }}
              >
                Next
              </Button>
              <CancelModal open={open} setOpen={setOpen} />
            </Box>
          </Grid>
        </Grid>
      </FormikProvider>
    </Box>
  );
};

export default BasicInformation;
