import { Modal, Form, Select, Input, Button, Row, Col } from "antd";
import "./style.scss";
import { useEffect } from "react";
import FormOrganization from "./FormOrganization";
import UserDetail from "../profile/UserDetail";

const ModalLocation = ({ detail, open, onClose }) => {
  const [form] = Form.useForm();

  const handleFinish = (values) => {
 
  };

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        first_name: detail?.first_name,
        last_name: detail?.last_name,
        email: detail?.email,
        phone: detail?.phone,
        title: detail?.title,
        role: detail?.role,
        location: detail?.location,
      });
    }
  }, [detail]);

  return (
    <Modal
      wrapClassName="modalLocation"
      open={open}
      onCancel={onClose}
      title=""
      footer={null}
    >
      <div className="headerPopup">
        <h3 className="title">Add Location</h3>
        <UserDetail name="Upload Logo" isModal={true} />
      </div>

      <FormOrganization isModal={true} />
    </Modal>
  );
};

export default ModalLocation;
