import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Switch,
  Chip,
  Skeleton,
  Rating,
  Stack,
} from "@mui/material";
import "../../../../pages/Reports/style.scss";
import "./style.scss";
import { format } from 'date-fns';
import ClockIcon from "../../../../assets/img/icons/ClockIcon.svg";


const MetricsCard = (props) => {
  const {
    title,
    amount,
    color,
    percent,
    icon,
    arrow,
    isPercentage = true,
    isToggle = false,
    isTime = false,
    isLoading,
    isRatingsCard = false,
    rating,
    totalRating,
    users,
  } = props;

  const [loading, setLoading] = useState(true);
  const [newRating, setNewRating] = useState();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  // Example dates; replace with your actual date logic
  const currentDate = new Date(); // Today's date
  const startDate = new Date(); // Start date for the range
  startDate.setDate(startDate.getDate() - 7); // Example: 7 days ago




  const formatDate = (dateString) => {
    if (!dateString) return "0"; // Handle missing or invalid date
    const date = new Date(dateString);
    if (isNaN(date)) return "0"; // Handle invalid date objects
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options); // e.g., 'Dec 28'
  };
  const dayOfWeek = currentDate ? format(new Date(currentDate), "EEEE") : "0"; // Handle missing `currentDate`
const formattedStartDate = startDate ? formatDate(startDate) : "0";
const formattedEndDate = currentDate ? formatDate(currentDate) : "0";
  
  const formatAmount = (value) => {
    if (value == null || isNaN(value)) {
      return "0"; // Handle missing or invalid numbers
    }
    const hasDecimalParts = value % 1 !== 0;
    const minimumFractionDigits = hasDecimalParts ? 2 : 0;
    const maximumFractionDigits = hasDecimalParts ? 2 : 0;
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };

  const formatNumber = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    return hasDecimalParts ? value?.toFixed(2) : value;
  };

  const convertDecimalHoursToTime = (decimalHours) => {
    if (decimalHours == null || isNaN(decimalHours)) {
      return "0:00 PM"; // Return a placeholder for invalid inputs
    }
  
    const totalMinutes = Math.round(decimalHours * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
  
    let formattedHours = date.getHours();
    const formattedMinutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = formattedHours >= 12 ? "PM" : "AM";
  
    // Convert to 12-hour format
    formattedHours = formattedHours % 12;
    formattedHours = formattedHours ? formattedHours : 12; // The hour '0' should be '12'
  
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  };
  const skeletonCard = () => {
    return (
      <Box className="metricsCard segmentStatsCard">
        <Box className="cardUpperSection" sx={{ marginBottom: "0px !important" }}>
          <Skeleton variant="circular" className="iconBox skeletonAvatar" active />
          <Box>
            <Typography variant="body1" className="dark-gray bold-500" style={{ marginTop: "10px" }}>
              <Skeleton className="skeletonHeading" active />
            </Typography>
          </Box>
          {isPercentage ? (
            <Skeleton className="skeletonChip percentageChip" active />
          ) : null}
        </Box>
        <Typography className="metricsCardAmount">
          <Skeleton className="skeletonValue" active />
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {loading ? (
        skeletonCard()
      ) : (
        <Box className="metricsCard segmentStatsCard"
          style={
            title === "Average Order Value"
              ? {
                // backgroundImage: `url(${bluebackground})`,
                paddingBottom: "20px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: '#1463C9',
                color: "white !important", // Debugging fallback
              }
              : { paddingBottom: "20px",boxShadow: "rgb(171 199 231) 0px 4px 8px" }
          }
        >
          <Box className="cardUpperSection" style={title === "Timing"?{ margin: "15px", marginLeft: "17px", marginBottom:"5px" }:{ margin: "15px", marginLeft: "17px" }}>
            <Box style={{ paddingRight: "18px" }} >{icon}</Box>
            <Box>
              <Typography variant="body1" className="dark-gray bold-500" style={
                title === "Average Order Value"
                  ? {
                    color: "white", // Debugging fallback
                  }
                  : { marginTop: "10px", marginRight: "10px", fontSize: "17px" }}>
                {title}
              </Typography>
            </Box>
            {isPercentage && title !== "Customer Acquisition Cost" && (
              <Chip
                icon={arrow}
                label={<span style={{ fontSize: "18px", paddingLeft: "12px", fontWeight: "500" }}>{`${formatAmount(percent)}%`}  </span>}
                variant="filled"
                size="small"
                style={{ padding: "12px 10px" }}
                className={`percentageChip ${color}`}
              />
            )}
            {isToggle ? (
              <Switch
                checked={toggle}
                onChange={() => setToggle(!toggle)}
                icon={<img src={ClockIcon} alt="Clock Icon" style={{ backgroundColor: "white", borderRadius: "10px", width: '20px', height: '20px' }} />}
                checkedIcon={<img src={ClockIcon} alt="Clock Icon" style={{ width: '20px', height: '20px', color: "white", borderRadius: "10px",backgroundColor:"white", }} />}
                sx={{
                  marginTop: "5px",
                  marginLeft: "auto",
                  color: "primary",
                  padding: "7px",
                  '&.Mui-checked': {
                    color: '#fff',
                  },
                  '& .MuiSwitch-switchBase': {
                    transitionDuration: '800ms',
                  },
                  '& .MuiSwitch-track': {
                    borderRadius: 22 / 2,
                    backgroundColor: "#002857",
                    opacity: 1,
                  },
                  '& .MuiSwitch-thumb': {
                    backgroundColor: "white",
                    width: 24,
                    height: 24,
                  },
                }}
              />
            ) : null}
          </Box>
          {isRatingsCard ? (
            <Box>
              <Box className="ratingBoxSegment">
                <Box>
                  <Typography className="rating">
                    
                    {newRating || "0.0"}
                  </Typography>
                  <Typography component="legend">
                    out of {totalRating || "5"}
                  </Typography>
                </Box>
                <Box style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <Stack spacing={1}>
                    <Rating
                      name="half-rating"
                      defaultValue={formatNumber(rating)}
                      onChange={(event, newValue) => setNewRating(newValue)}
                      precision={0.5}
                    />
                  </Stack>
                </Box>
              </Box>
              <Typography className="ratingDescription" style={{ paddingBottom: "17px" }}>
                {newRating || formatNumber(rating)|| "0"}-star average from more than {users || "0.000"} users
              </Typography>
            </Box>
          ) : (
            <div>
              <Typography style={title === "Timing" ? { textAlign: "center", marginBottom:"-10px", fontWeight: "500", fontSize: "18px", color: "#9F9F9F" }: { textAlign: "center", fontWeight: "500", fontSize: "18px", color: "#9F9F9F" }} variant="h6">
                {title === "Timing" ? <span style={{fontSize:"18px", marginBottom:"-15px"}}>{dayOfWeek}</span> : null}
              </Typography>
              <Typography className="metricsCardAmount" style={
                title === "Average Order Value"
                  ? {
                    color: "white", // Debugging fallback
                  }
                  : {}}>
                {title === "Timing"
                  ? toggle
                    ? `${convertDecimalHoursToTime(amount?.avgTime)}`
                    : `${convertDecimalHoursToTime(amount?.medianTime)}`
                  : `$${formatAmount(amount)}`}
              </Typography>
              <Typography style={{ fontWeight: "600", textAlign: "center", fontSize: "15px", color: "#9F9F9F" }} variant="body2">
                {title === "Timing"
                  ? <span style={{marginTop:"-10px"}}>{`${formattedStartDate} - ${formattedEndDate}`}</span>
                  : null}
              </Typography>
            </div>
          )}
        </Box>
      )}
    </>
  );
};

export default MetricsCard;
