/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import { Helmet } from "react-helmet";
import useWindowSize from "../../hooks/useWindowSize";

import "./style.scss";

const Layout = () => {
  const { width } = useWindowSize();
  const [showSidebar, setShowSidebar] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = useCallback(
    () => setShowSidebar((value) => !value),
    []
  );

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  // Set sidebar visibility based on screen width
  useEffect(() => {
    setShowSidebar(width < 1199 && width > 769);
  }, [width]);

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (showSidebar && !e.target.closest(".sidebar")) {
        setShowSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showSidebar]);

  if (localStorage.token) {
    return (
      <div
        className={`layout${isMobile ? " mobile" : ""}${
          isTablet ? " tablet" : ""
        }`}
      >
        <div className="layout-main">
          {/* Pass toggleSidebar correctly */}
          <SideBar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
          <div className={`layout-main-body ${showSidebar && "expended"}`}>
            <Header onClick={toggleSidebar} />
            <div
              className="layout-main-body-content"
              id="layout-main-body-content"
              style={{
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />

        <Helmet>
          {/* <!-- Start of HubSpot Embed Code --> */}
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-na1.hs-scripts.com/43627519.js"
          ></script>
          {/* <!-- End of HubSpot Embed Code --> */}
        </Helmet>
      </div>
    );
  } else {
    return null;
  }
};

export default Layout;
