import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import * as yup from "yup";
import "./style.scss";
import CancelModal from "../CancelModal/index.jsx";
import { setLocationInfo } from "../../../redux/actions/onboarding.js";
import { countryList } from "../../../pages/Onboarding/constants.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
function CompanyInfoForm(props) {
  const [openCancel, setOpenCancel] = useState(false);
  const dispatch = useDispatch();
  const [submitData, setSubmitData] = useState(false);
  const { onboarding } = useSelector((state) => state.onboarding);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" }); // Check if the screen is mobile size
  const formik = useFormik({
    initialValues: {
      companyLegalName: "",
      doingBusinessName: "",
      country: "",
    },
    validationSchema: yup.object().shape({
      companyLegalName: yup
        .string()
        .required("Please enter your Company's Legal Name")
        .matches(/\S/, "Invalid Company's Legal Name"),
      doingBusinessName: yup
        .string()
        .required("Please enter your Company's Business Name")
        .matches(/\S/, "Invalid Company's Business Name"),
      country: yup.string().required("Please select your Country"),
    }),
    onSubmit: (values) => {
   
      localStorage.setItem("country", values.country);
      localStorage.setItem("companyLegalName", values.companyLegalName);
      localStorage.setItem("doingBusinessName", values.doingBusinessName);
      dispatch(
        setLocationInfo({
          email: onboarding.account.email,
          country: values.country,
          company_legal_name: values.companyLegalName || "",
          dba_name: values.doingBusinessName,
        })
      );
    },
  });

  const handleSubmit = async () => {
    setSubmitData(true);
    formik.setTouched({
      companyLegalName: true,
      doingBusinessName: true,
      country: true,
    });
    await formik.submitForm();
  };
  
  const [openStates, setOpenStates] = useState({
    country: false,
  });

  const handleOpen = (id) => setOpenStates((prev) => ({ ...prev, [id]: true }));
  const handleClose = (id) =>
    setOpenStates((prev) => ({ ...prev, [id]: false }));

  useEffect(() => {
    if (onboarding && onboarding.companyInfo) {
      formik.setFieldValue(
        "companyLegalName",
        onboarding.companyInfo.company_legal_name || ""
      );
      formik.setFieldValue(
        "doingBusinessName",
        onboarding.companyInfo.dba_name || ""
      );
      formik.setFieldValue(
        "country",
        onboarding.companyInfo.company_based_location || ""
      );
    }
  }, [onboarding]);

  const handleCancelButtonClick = () => {
    setOpenCancel(true);
  };

  const RotatingArrowIcon = ({ open }) => (
    <ArrowDropDownIcon
      style={{
        transform: open ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease",
        marginRight: "4px",
      }}
    />
  );
  return (
    <Box
      className="company"
      style={{ padding: isMobile ? "52px 15px" : "70px 80px" }}
    >
      <Box className="company-sec">
        <Typography
          variant="h6"
          className="heading"
          style={{
            fontSize: isMobile ? "16px" : "20px",
            textAlign: "left",
            marginBottom: isMobile ? "3px" : "10px",
          }}
        >
          Company Information
        </Typography>
        <Typography
          variant="body1"
          className="subHeading"
          style={{
            fontSize: isMobile ? "12px" : "16px",
            marginBottom: isMobile ? "40px" : "60px",
          }}
        >
          Please provide all the information below
        </Typography> 

        <Typography
          className="heading"
          style={{
            textAlign: "left",
            marginTop: "-3%",
            fontSize: isMobile ? "12px" : "18px",
          }}
        >
          Where is your company located?
        </Typography>
        <FormikProvider value={formik}>
          <form className="companyName-form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              {/* Full-width country field */}
              <Grid item xs={12}>
                <Box className="formItem">
                  <FormControl fullWidth>
                    <InputLabel shrink={false}>
                      {formik.values.country ? "" : "Country"}
                    </InputLabel>
                    <Select
                      open={openStates.country}
                      onOpen={() => handleOpen("country")}
                      onClose={() => handleClose("country")}
                      IconComponent={(props) => (
                        <RotatingArrowIcon
                          open={openStates.country}
                          {...props}
                        />
                      )}
                      id="country"
                      sx={{
                        // Styling the selected text in the Select field
                        "& .MuiSelect-select": {
                          color: formik.values.country ? "#4A4C4D" : "#C8C8CE", // Placeholder color if no value selected
                          fontSize: "16px",
                          fontWeight: formik.values.country ? "500" : "bold", // Bold font if placeholder
                        },
                        // Styling the dropdown menu items
                        "& .MuiMenuItem-root": {
                          color: "#4A4C4D",
                          fontSize: "16px",
                          fontWeight: "500",
                        },
                        // Styling the dropdown icon (the arrow)
                        "& .MuiSvgIcon-root": {
                          color: "#4A4C4D",
                        },
                      }}
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    >
                      {countryList.map((selectedCountry, index) => (
                        <MenuItem key={index} value={selectedCountry}>
                          {selectedCountry}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="caption" color="error">
                    {formik.touched.country}
                  </Typography>
                </Box>
              </Grid>

              {/* Two name fields side by side */}
              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography
                    variant="body1"
                    className="formHead mobileFieldLegal"
                    style={{
                      fontSize: isMobile ? "12px" : "18px",
                      marginTop: isMobile ? "0px" : "8px",
                      fontWeight: "600",
                    }}
                  >
                    Company legal name
                  </Typography>
                  <TextField
                    id="companyLegalName"
                    placeholder="ABC, Inc."
                    sx={{
                      // Styling the input text
                      "& .MuiInputBase-input": {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500", // Font weight
                        WebkitTextFillColor: "#4A4C4D",
                      },
                      // Styling the placeholder
                      "&::placeholder": {
                        color: "#C8C8CE",
                        fontWeight: "bold",
                      },
                    }}
                    variant="outlined"
                    name="companyLegalName"
                    value={formik.values.companyLegalName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.companyLegalName && submitData
                    )}
                    helperText={
                      submitData ? formik.errors.companyLegalName : ""
                    }
                  />
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className="formItem">
                  <Typography
                    variant="body1"
                    className="formHead"
                    style={{
                      fontSize: isMobile ? "12px" : "18px",
                      marginTop: isMobile ? "0px" : "8px",
                      fontWeight: "600",
                    }}
                  >
                    Doing business as name
                  </Typography>
                  <TextField
                    id="doingBusinessName"
                    sx={{
                      // Styling the input text
                      "& .MuiInputBase-input": {
                        color: "#4A4C4D", // Input text color
                        fontSize: "16px", // Adjusting the font size
                        fontWeight: "500", // Font weight
                        WebkitTextFillColor: "#4A4C4D",
                      },
                      // Styling the placeholder
                      "&::placeholder": {
                        color: "#C8C8CE",
                        fontWeight: "bold",
                      },
                    }}
                    placeholder="ABC"
                    variant="outlined"
                    name="doingBusinessName"
                    value={formik.values.doingBusinessName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.errors.doingBusinessName && submitData
                    )}
                    helperText={
                      submitData ? formik.errors.doingBusinessName : ""
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </form>

          <Grid item xs={12}>
            <Box className="formButtons">
              <Button variant="outlined" onClick={handleCancelButtonClick}>
                Back
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Next
              </Button>
              <CancelModal open={openCancel} setOpen={setOpenCancel} />
            </Box>
          </Grid>
        </FormikProvider>
      </Box>
    </Box>
  );
}

export default CompanyInfoForm;
