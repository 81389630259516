import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import dataline from "@iconify-icons/majesticons/data-line";
import usersFill from "@iconify-icons/ph/users-fill";
import settings from "@iconify-icons/material-symbols/settings";
import reportsBox from "@iconify-icons/mdi/report-box";
import outLineClose from "@iconify-icons/ic/outline-close";
import cloudOutline from "@iconify-icons/material-symbols/cloud-outline";
import { useMediaQuery } from "react-responsive";

import LogoDesktop from "../../../assets/img/logo-desktop.svg";
import LogoExpended from "../../../assets/img/logo-closed.svg";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

const SideBar = ({ showSidebar, toggleSidebar }) => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const location = useLocation();
  const [menus] = useState([
    {
      icon: usersFill,
      router: "/customers",
      title: "Customers",
    },
    {
      icon: dataline,
      router: "/connections",
      title: "Connections",
    },
    {
      icon: reportsBox,
      router: "/reports",
      title: "Insights",
    },
    {
      icon: cloudOutline,
      router: "/apikeys",
      title: "APIs",
    },
    {
      icon: settings,
      router: "/settings",
      title: "Settings",
    },
  ]);

  const sidebarRef = useRef(null);

  // Close sidebar on outside click for mobile screens
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (isMobile && showSidebar) {
          toggleSidebar(false); // Close the sidebar
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMobile, showSidebar, toggleSidebar]);

  return (
    <>
      {/* Sidebar */}
      <aside
        ref={sidebarRef}
        className={`sidebar${showSidebar ? " open" : ""}${
          isMobile ? " mobile" : ""
        }${isTablet ? " tablet" : ""}`}
      >
        <div className="sidebar-logo">
          {showSidebar && !isMobile && (
            <img src={LogoExpended} alt="Desktop Logo" />
          )}
          {!showSidebar && !isMobile && (
            <img src={LogoDesktop} alt="Desktop Logo" />
          )}
          {showSidebar && isMobile && (
            <img src={LogoDesktop} alt="Desktop Logo" />
          )}
        </div>
        <ul className="sidebar-list">
          {menus.map((item, idx) => (
            <li key={idx}>
              <Link
                to={item.router}
                className={`sidebar-list-item${
                  location.pathname === item.router ? " active" : ""
                }`}
              >
                <Icon className="sidebar-list-item-icon" icon={item.icon} />
                <p className="sidebar-list-item-caption">{item.title}</p>
              </Link>
            </li>
          ))}
        </ul>
      </aside>

      {/* Backdrop for mobile */}
      {isMobile && showSidebar && (
        <div
          className="backdrop"
          onClick={() => toggleSidebar(false)} // Close sidebar on backdrop click
        ></div>
      )}

      {/* Close button */}
      {isMobile && (
        <div className={`mobile-sidebar-close${!showSidebar ? " show" : ""}`}>
          <p
            className="mobile-sidebar-close-btn"
            onClick={() => toggleSidebar(false)}
          >
            <Icon icon={outLineClose} />
          </p>
        </div>
      )}
    </>
  );
};

export default SideBar;
