import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Select,
  MenuItem,
  Box,
  Button,
  TextField,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import CocaColaIcon from "../../../assets/img/icons/cocaColaicon.svg";
import ikeaLogo from "../../../assets/img/icons/ikeaLogo.svg";
import mazdaIcon from "../../../assets/img/icons/mazdaIcon.svg";
import rvLog from "../../../assets/img/icons/rvLog.svg";
import hrLogo from "../../../assets/img/icons/hrLogo.svg";
import premium from "../../../assets/img/icons/premiumlogo.svg";
import aeroplanLogo from "../../../assets/img/icons/aeroplanLogo.svg";
import SceneLogo from "../../../assets/img/icons/scenelogo.svg";
import moreReward from "../../../assets/img/icons/moreReward.svg";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAccountInfo } from "../../../redux/actions/settings";
import { Chip } from "@mui/material";
import { getAllPersonas } from "../../../redux/actions/dashboard";
const SearchOverlay = ({ open, onClose }) => {
  const [persona, setPersona] = useState("");
  const [primaryPersona, setPrimaryPersona] = useState();
  const [power, setPower] = useState("");
  const [brand, setBrand] = useState("");
  const [location, setLocation] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [showResults, setShowResults] = useState(false); // Track the visibility of "Show Results"
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [personaData, setPersonaData] = useState();
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const { personas } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { accountInfo } = useSelector((state) => state.settings);
  const handleClose = () => onClose(false);
  const handleShowResults = () => {
    setShowSearch(true);
    setShowResults(true);
    // Simulated results (replace with actual search logic if necessary)
    setResults([
      "John Doe - john@example.com",
      "Jane Smith - jane@example.com",
    ]);
  };
  const getInitials = (name) => {
    const names = name?.split(" ");
    const initials = names
      ?.map((n) => n[0])
      .join("")
      ?.toUpperCase();
    return initials;
  };

  const handlePersonaSelect = (persona) => {
    setSelectedPersonas((prev) => {
      if (prev.includes(persona)) {
        return prev.filter((p) => p !== persona);
      } else {
        return [...prev, persona];
      }
    });
  };
  const handleBrandSelect = (brand) => {
    setSelectedBrands((prev) =>
      prev.includes(brand)
        ? prev.filter((item) => item !== brand)
        : [...prev, brand]
    );
  };
  // useEffect(() => {
  //   //    setLoading(true);
  //   dispatch(getAllPersonas(token));
  // }, [token]);

  useEffect(() => {
    if (personas) {
      setPersonaData(personas);
    }
  }, [personas]);
  // useEffect(() => {
  //   dispatch(getAccountInfo());
  // }, []);

  useEffect(() => {
    if (accountInfo) {
      localStorage.setItem("roles", accountInfo?.roles);
    }
  }, [accountInfo]);
  console.log("acc:", accountInfo);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const items = [
    {
      src: CocaColaIcon,
      alt: "CocaCola",
      value: "BrandA",
      text: "Coca Cola",
    },
    {
      src: ikeaLogo,
      alt: "Ikea",
      value: "BrandB",
      text: "Ikea",
    },
    {
      src: mazdaIcon,
      alt: "Mazda",
      value: "BrandC",
      text: "Mazda",
    },
    {
      src: rvLog,
      alt: "RV",
      value: "BrandD",
      text: "Rove Concepts",
    },
    {
      src: hrLogo,
      alt: "HR",
      value: "BrandE",
      text: "Holt Renfrew",
    },
    {
      src: premium,
      alt: "Premium",
      value: "BrandF",
      text: "Premium",
    },
    {
      src: moreReward,
      alt: "Scene",
      value: "MoreReward",
      text: "More Rewards",
    },
    {
      src: aeroplanLogo,
      alt: "Aeroplan",
      value: "BrandG",
      text: "Aeroplan",
    },
    {
      src: SceneLogo,
      alt: "Scene",
      value: "Scene",
      text: "Scene Rewards",
    },
  ];
  const RotatingArrowIcon = ({ open }) => (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: open ? "rotate(0deg)" : "rotate(180deg)", // Arrow points down initially
        transition: "transform 0.3s ease",
        marginRight: "4px",
      }}
    >
      <path
        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
        fill="#002857"
      />
    </svg>
  );
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "35%",
          padding: "20px",
          backgroundColor: "#F5F7F9",
          justifyContent: "space-between",
          height: "95%",
        },
      }}
    >
      {showResults ? (
        <>
          <Box
            sx={{
              width: "100%",
              mt: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            {/* Show Results Button */}
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10,
              }}
            >
              <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                placeholder="Search with name, email, number"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                InputLabelProps={{
                  shrink: false,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "white",
                    borderRadius: "10px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "11px 20px",
                    fontWeight: "500",
                  },
                  "& .MuiOutlinedInput-input::placeholder": {
                    color: "#002857",
                    opacity: 1,
                    fontSize: "16px",
                  },
                }}
                InputProps={{
                  sx: {
                    "&::placeholder": {
                      color: "#002857", // Placeholder color
                      fontSize: "16px", // Placeholder font size
                      opacity: 1, // Ensure opacity is 1 for visible placeholder
                    },
                  },
                }}
              />
            </Box>

            <List>
              <h4
                style={{
                  fontSize: "16px",
                  color: "#002857",
                  marginBottom: "10px",
                  marginLeft: "4%",
                }}
              >
                {" "}
                Recent Searches
              </h4>
              {results.map((result, index) => (
                <ListItem
                  style={{
                    fontFamily: "Gotham",
                    marginLeft: "1%",
                    color: "#999999",

                    fontWeight: "500",
                  }}
                  key={index}
                >
                  {result}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mt: 4,
            }}
          >
            <Button
              sx={{ borderRadius: "20px", width: "60%" }}
              variant="contained"
              onClick={handleShowResults}
            >
              Show Results
            </Button>
          </Box>
        </>
      ) : (
        // Render dropdowns and inputs
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            {/* Close Icon */}
            <Box
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 10,
              }}
            >
              <IconButton onClick={handleClose} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box sx={{ width: "100%", mb: 4, mt: 10 }}>
              <Accordion
                sx={{ boxShadow: "none", borderRadius: "10px !important" }}
              >
                <AccordionSummary
                  sx={{ minHeight: "41px" }}
                  expandIcon={<RotatingArrowIcon open={open} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div
                    style={{
                      display: "flex",
                      flexGrow: "1",
                      marginRight: "5%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        color: "#002857",
                        fontWeight: "500",
                        fontFamily: "Gotham",
                      }}
                    >
                      PERSONAS
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {selectedPersonas.length > 2 ? (
                        <>
                          {selectedPersonas
                            .slice(0, 2)
                            .map((persona, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 7px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F5F7F9",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: "#002857",
                                  fontFamily: "Gotham",
                                }}
                              >
                                {persona}
                              </span>
                            ))}
                          <span
                            style={{
                              padding: "2px 7px",
                              borderRadius: "5px",
                              backgroundColor: "#F5F7F9",
                              fontSize: "10px",
                              fontWeight: "400",
                              color: "#002857",
                              fontFamily: "Gotham",
                            }}
                          >
                            +{selectedPersonas.length - 2}
                          </span>
                        </>
                      ) : (
                        <>
                          {selectedPersonas.map((persona, index) => (
                            <span
                              key={index}
                              style={{
                                padding: "2px 7px",
                                borderRadius: "5px",
                                backgroundColor: "#F5F7F9",
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#002857",
                                fontFamily: "Gotham",
                              }}
                            >
                              {persona}
                            </span>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </AccordionSummary>

                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      gridTemplateColumns: "repeat(2, auto)",
                      columnGap: "25px",
                      rowGap: "10px",
                      padding: 0,
                    }}
                  >
                    {personaData
                      ?.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => t.personaLabel === value.personaLabel
                          )
                      )
                      .map((email) => (
                        <Chip
                          key={email.personaId}
                          label={email.personaLabel}
                          variant="filled"
                          style={{
                            width: "190px",
                            height: "28px",
                            fontSize: "14px",
                            borderRadius: "5px",
                            fontFamily: "Gotham",
                            color: "#002857",
                            backgroundColor: "#F5F7F9",
                            border: selectedPersonas.includes(
                              email.personaLabel
                            )
                              ? "2px solid rgb(2, 175, 239)"
                              : "none",
                          }}
                          onClick={() => {
                            handlePersonaSelect(email.personaLabel);
                          }}
                        />
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Accordion for Buying Power */}
            <Box sx={{ width: "100%", mb: 4 }}>
              <Accordion
                sx={{ boxShadow: "none", borderRadius: "10px !important" }}
              >
                <AccordionSummary
                  sx={{ minHeight: "41px" }}
                  expandIcon={<RotatingArrowIcon open={open} />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#002857",
                      fontWeight: "500",
                      fontFamily: "Gotham",
                    }}
                  >
                    BUYING POWER
                  </span>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    padding: "8px 0px 16px",
                  }}
                >
                  {/* <Select
              fullWidth
              value={power}
              onChange={(e) => setPower(e.target.value)}
              displayEmpty
              sx={{
                backgroundColor: "white",
                border: "1px solid white",
                height: "30px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
            > */}
                  {/* <MenuItem value="">Select Power</MenuItem> */}
                  <MenuItem value="5%">5%</MenuItem>
                  <MenuItem value="10%">10%</MenuItem>
                  <MenuItem value="20%">20%</MenuItem>
                  <MenuItem value="30%">30%</MenuItem>
                  <MenuItem value="40%">40%</MenuItem>
                  <MenuItem value="50%">50% and below </MenuItem>

                  {/* </Select> */}
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Accordion for Brands */}
            <Box sx={{ width: "100%", mb: 4 }}>
              <div>
                <Accordion
                  sx={{ boxShadow: "none", borderRadius: "10px !important" }}
                >
                  <AccordionSummary
                    sx={{ minHeight: "41px" }}
                    expandIcon={<RotatingArrowIcon open={open} />}
                    aria-controls="brands-content"
                    id="brands-header"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                        marginRight: "5%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#002857",
                          fontWeight: "500",
                          fontFamily: "Gotham",
                        }}
                      >
                        BRANDS
                      </span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {selectedBrands.length > 2 ? (
                          <>
                            {selectedBrands.slice(0, 2).map((brand, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 7px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F5F7F9",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: "#002857",
                                  fontFamily: "Gotham",
                                }}
                              >
                                {brand}
                              </span>
                            ))}
                            <span
                              style={{
                                padding: "2px 7px",
                                borderRadius: "5px",
                                backgroundColor: "#F5F7F9",
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#002857",
                                fontFamily: "Gotham",
                              }}
                            >
                              +{selectedBrands.length - 2}
                            </span>
                          </>
                        ) : (
                          <>
                            {selectedBrands.map((brand, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 7px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F5F7F9",
                                  fontSize: "10px",
                                  fontWeight: "400",
                                  color: "#002857",
                                  fontFamily: "Gotham",
                                }}
                              >
                                {brand}
                              </span>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div
                      style={{
                        display: "grid",
                        justifyContent: "center",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        columnGap: "8px",
                        rowGap: "19px",
                        padding: 0,
                      }}
                    >
                      {items.map(({ src, alt, value, text }, index) => (
                        <MenuItem
                          key={index}
                          value={value}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "10px",
                            padding: "7px",
                            borderRadius: "5px",
                            color: "#002857",
                            backgroundColor: "#F5F7F9",
                            border: selectedBrands.includes(text)
                              ? "2px solid #02afef"
                              : "none",
                            width: "100%",
                          }}
                          onClick={() => handleBrandSelect(text)}
                        >
                          <img
                            src={src}
                            alt={alt}
                            width="68"
                            height="40"
                            style={{ marginTop: "4px" }}
                          />
                          <span
                            style={{ marginTop: "13px", fontWeight: "500" }}
                          >
                            {text}
                          </span>
                        </MenuItem>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Box>

            {/* Accordion for Location */}
            <Box sx={{ width: "100%", mb: 4 }}>
              <Accordion
                sx={{ boxShadow: "none", borderRadius: "10px !important" }}
              >
                <AccordionSummary
                  sx={{ minHeight: "41px" }}
                  expandIcon={<RotatingArrowIcon open={open} />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#002857",
                      fontWeight: "500",
                      fontFamily: "Gotham",
                    }}
                  >
                    LOCATION
                  </span>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    color: "#002857",
                    fontFamily: "Gotham",
                    padding: "8px 0px 16px",
                  }}
                >
                  {/* <Select
              fullWidth
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              displayEmpty
              sx={{
                backgroundColor: "white",
                border: "1px solid white",
                height: "30px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
            > */}
                  {/* <MenuItem value="">Select Location</MenuItem> */}
                  {accountInfo?.additional_locations && (
                    <>
                      <MenuItem value="Location1">
                        {accountInfo?.address_line_1}
                      </MenuItem>
                      {accountInfo?.location_name && (
                        <MenuItem value="Location2">
                          {accountInfo?.location_name}
                        </MenuItem>
                      )}
                    </>
                  )}
                  {/* </Select> */}
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* Postal Code */}
            <TextField
              fullWidth
              placeholder="POSTAL CODE"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              InputLabelProps={{
                shrink: false,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "13px 16px",
                  fontWeight: "500",
                },
                "& .MuiOutlinedInput-input::placeholder": {
                  color: "#002857",
                  opacity: 1,
                  fontSize: "16px",
                },
              }}
            />
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mt: 4,
            }}
          >
            <Button
              sx={{ borderRadius: "20px", width: "60%" }}
              variant="contained"
              color="primary"
              onClick={handleShowResults}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Drawer>
  );
};

export default SearchOverlay;
