import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box} from '@mui/material';
import ModalPlanDetail from './modalPlanDetail';
import { useMediaQuery } from "react-responsive";

const PlanCard = ({ plan, isCurrent, onSelectPlan, currentPlan }) => {
    
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 769 });
    
  return (
    <>   
    <Card sx={{
      border: isCurrent ? '3px solid rgba(2, 175, 239, 1)': '1px solid #e0e0e0',
      boxShadow: 'none',
      borderRadius: '8px',
      ':hover': {
        boxShadow: '0 2px 14px rgba(0,0,0,0.1)'
      },
      bgcolor: '#fff',
      display: 'flex', flexDirection: 'row'
      
    }}
    onClick={() => onSelectPlan(plan.id)}
    ><Typography 
    variant="h1" 
    component="div" 
    sx={{
        backgroundColor: isCurrent ? "rgba(2, 175, 239, 0.2)" : "rgba(153, 153, 153, 0.2)",
        fontSize: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        fontWeight: "500",
        color: isCurrent ? 'rgba(2, 175, 239, 1)' : "rgba(153, 153, 153, 1)"
    }}
>
    {plan.id === currentPlan && (
        <div style={{ fontSize: "10px", paddingBottom: "2px",marginTop:isMobile?"32px":"" }}>Current Plan</div>
    )}
    <div style={{ marginTop: isMobile ? (plan.name === "Free" ? "" : "31px") : "" }}>
        {plan.name}
    </div>
</Typography>


      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex:"1" }}>      
         
 
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography sx={{ mb: 1.5, color: 'text.secondary',  fontSize: isMobile? "12px":'14px',fontWeight:"500", paddingTop: plan.name === "Free"
            ? (plan.id === currentPlan && !isCurrent ? "19px" : "12px") 
            : "12px",  marginBottom: "0px" }}>
            {plan.description}   
        <Button 
          size="small" 
          href={plan.detailsUrl}
          sx={{
            textTransform: 'none',
            fontWeight: '500',
            color:"#0050BC",
            textDecoration:"underline",
            fontSize:"14px", 
            paddingTop: "0px"
          }}
          onClick={() => setOpen(true)}
          >
          Plan details
        </Button>
          </Typography>
          </Box>
        <Typography variant="body2" sx={{ fontWeight: 'medium',  fontSize: isMobile? "13px": "1rem", marginTop: "12px",marginBottom: "0px",color:"#002857"}}>
          {plan.price} / month
        </Typography>
      </CardContent>
      <ModalPlanDetail open={open} onClose={() => setOpen(false)} selectedPlan={plan.id} />

    </Card>
    </>
  );
};

export default PlanCard;
