/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import {
  DatePicker,
  Carousel,
  Button,
  Skeleton,
  Empty,
  Card,
} from "antd";
import { useMediaQuery } from "react-responsive";

import { Icon } from "@iconify/react";
import EyeIcon from "@iconify-icons/streamline/interface-edit-view-eye-eyeball-open-view";
import DateIcon from "@iconify-icons/fontisto/date";
import moment from "moment";

import SummaryCard from "../../components/common/SummaryCard";
import CustomerDetailDrawer from "../../components/common/CustomerDetailDrawer";
import CustomerDataBox from "../../components/common/CustomerDataBox";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCutomerData,
  getCustomerData,
  getSummaryData,
  getAsyncData,
  getAllPersona,
} from "../../redux/actions/co-pliot";
import useWindowSize from "../../hooks/useWindowSize";
import dayjs from "dayjs";
import arrwoRightIcon from "@iconify-icons/mdi/arrow-right-thin";
import "./style.scss";
import CheckInsList from "../../components/CoPilot/CheckInsList";
import ButtonRangePicker from "../../components/common/ButtonRangePicker";

const { RangePicker } = DatePicker;


const filterFormat = "YYYY-MM-DD";

const CoPilot = () => {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const { width } = useWindowSize();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
 
  const [dateFilters, setDateFilters] = useState([
    dayjs().add(-7, "d"),
    dayjs(),
  ]);
  const [loading, setLoading] = useState(true);
  const [dataCheckins, setDataCheckins] = useState([]);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });

  const {
    summaryData,
    customers,
    customer,
    loadingCustomer,
    loadingCustomers,
    loadingSummaryData,
    loadedCustomers,
    loadedSummary,
    asyncData,
    loadingAsync,
    loadedAsync,
    rates,
  } = useSelector((state) => state.coPilot);

  const compareDates = (a, b) => {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };


  useEffect(() => {
    if (customers) {
      setLoadingFilter(false);
      let data = customers.sort(compareDates);

      setDataCheckins(data);
    }
  }, [customers]);

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
    
      // dispatch(
      //   getAllCutomerData({
      //     start_date: dates[0].format(filterFormat),
      //     end_date: dates[1].format(filterFormat),
      //   })
      // );
    } else {
 
      dispatch(getAllCutomerData());
    }
    setDateFilters(dates);
  };

  const handleSelectedDates = (dates) => {
    if (dates.length > 1) {
      setLoadingFilter(true);
      dispatch(
        getAllCutomerData({
          start_date: dates[0].format(filterFormat),
          end_date: dates[1].format(filterFormat),
        })
      );
    }
  };

  const handleFilter = (dates) => {
    if (dates.startDate && dates.endDate) {
      setLoadingFilter(true);
      dispatch(
        getAllCutomerData({
          start_date: moment(dates.startDate).format(filterFormat),
          end_date: moment(dates.endDate).format(filterFormat),
        })
      );
    }
  };

  useEffect(() => {
    // setLoading(true);
    if (filters) {
      dispatch(
        getAllCutomerData({
          start_date: moment(filters.startDate).format(filterFormat),
          end_date: moment(filters.endDate).format(filterFormat),
        })
      );
    }
    dispatch(getSummaryData());
    dispatch(getAllPersona());
    // handleSyncData()
  }, []);

  const loadData = () => {
    if (filters) {
      dispatch(
        getAllCutomerData({
          start_date: moment(filters.startDate).format(filterFormat),
          end_date: moment(filters.endDate).format(filterFormat),
        })
      );
    } else {
      dispatch(getAllCutomerData());
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs().endOf("day")],
    },
    {
      label: "Last 24 hours",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
  ];

  useEffect(() => {
    if (loadedSummary === true && loadedCustomers === true) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadedSummary, loadedCustomers]);

  const handleSyncData = () => {
    setLoading(true);
    dispatch(getAsyncData());
  };

  const skeletonRowTable = () => {
    let results = [];
    for (let i = 0; i < 6; i++) {
      results.push(
        <tr key={i}>
          <td>
            <Skeleton.Button
              active
              shape="round"
              size="small"
              style={{ width: "120px" }}
            />
          </td>
          <td>
            <Skeleton.Avatar
              active
              size="small"
              shape="circle"
              style={{ marginRight: "5px" }}
            />
            <Skeleton.Button
              active
              shape="round"
              size="small"
              style={{ width: "120px" }}
            />
          </td>
          <td>
            <Skeleton.Input active size="small" />
          </td>
          <td>
            <Skeleton.Button
              active
              shape="round"
              size="small"
              style={{ width: "40px" }}
            />
          </td>
          <td>
            <Skeleton.Button
              active
              shape="round"
              size="small"
              style={{ width: "51px" }}
            />
          </td>
          <td>
            <Icon className="view-icon" icon={EyeIcon} />
          </td>
        </tr>
      );
    }
    return results;
  };

  const skeletonCards = () => {
    let results = [];
    for (let i = 0; i < 4; i++) {
      results.push(
        <div
          className={`summaryCard${isMobile ? " mobile" : ""}${
            isTablet ? " tablet" : ""
          }`}
          key={i}
        >
          <div className="summaryCard-info">
            <div
              className="summaryCard-info-detail"
              style={{ paddingRight: "10px" }}
            >
              <Skeleton.Button
                active
                size="small"
                shape="round"
                block
                style={{ width: "100%", marginBottom: "10px" }}
              />

              <Skeleton.Button
                active
                block
                shape="round"
                style={{ width: "100%" }}
              />
            </div>
            <div className="summaryCard-info-icon">
              <Skeleton.Avatar
                shape="circle"
                size="large"
                style={{
                  width: width > 1024 ? "60px" : "40px",
                  height: width > 1024 ? "60px" : "40px",
                }}
              >
                <img src="" alt="icon" />
              </Skeleton.Avatar>
            </div>
          </div>
          <a href="#" className="summaryCard-viewLink">
            See Details <Icon className="arrow-icon" icon={arrwoRightIcon} />
          </a>
        </div>
      );
    }

    return results;
  };

  const skeletonCheckinCards = () => {
    let results = [];
    for (let i = 0; i < 6; i++) {
      results.push(
        <div className={`customerDataBox${isMobile ? " mobile" : ""}`} key={i}>
          <Skeleton active />
        </div>
      );
    }

    return results;
  };

  const datePickerEl = (
    <RangePicker
      suffixIcon={<Icon icon={DateIcon} color="#002857" />}
      separator={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#002857"
          className="w-6 h-6"
          width={20}
          height={20}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
          />
        </svg>
      }
      onChange={onRangeChange}
      onOk={handleSelectedDates}
      showTime={{ format: "HH" }}
      format={width > 576 ? "MM/DD/YYYY" : "MM/DD/YY"}
      className="copilot-content-body-header-tools-calendar copilot-content-body-header-tools-rangerpicker custom-rangepicker"
      popupClassName="custom-calendar"
      value={dateFilters}
      renderExtraFooter={() => (
        <div className="copilot-content-body-header-tools-footer">
          {rangePresets &&
            rangePresets.length > 0 &&
            rangePresets.map((item, i) => (
              <Button
                key={i}
                size="small"
                onClick={() => setDateFilters(item.value)}
              >
                {item.label}
              </Button>
            ))}
        </div>
      )}
    />
  );

  return (
    <div
      className={`copilot${isMobile ? " mobile" : ""}${
        isTablet ? " tablet" : ""
      }`}
    >
      <CustomerDetailDrawer
        isOpen={isDrawerOpen}
        onClose={onClose}
        customer={customer}
        isMobile={isMobile}
        isLoading={loadingCustomer}
        onLoadData={loadData}
      />
      {width > 1024 && (
        <div className="copilot-datetime">
          {loading ? (
            <Skeleton.Input active size="small" style={{ width: "180px" }} />
          ) : (
            <div>
              <ButtonRangePicker onFilter={handleFilter} showIcon={false} />
            </div>
          )}
        </div>
      )}
      <div className="copilot-header">
        <h1 className="copilot-header-title">Summary</h1>
        <div className="showFilter" style={{paddingLeft:isMobile? "80%":"158px"}}>
          <ButtonRangePicker showIcon={true} onFilter={handleFilter} />
        </div>
      </div>
      <div className="copilot-content">
        {width < 1025 ? (
          loading ? (
            <div
              className={`customerDataBox${isMobile ? " mobile" : ""}`}
              style={{ marginBottom: "16px" }}
            >
              <Skeleton active />
            </div>
          ) : (
            <div className="copilot-content-sliderBox">
              <Carousel
                autoplay
                // speed={5000}
                // waitForAnimate={true}
                // initialSlide={{}}
                infinite={true}
                slidesToShow={1}
                slidesToScroll={1}
                dots={true}
                arrows={false}
                variableWidth={true}
              >
                {summaryData.map((summary, idx) => (
                  <SummaryCard
                    title={summary.title}
                    value={summary.value || 0}
                    linkCaption={summary.linkCaption}
                    icon={summary.icon}
                    iconBkColor={summary.iconBkColor}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    loading={loadingSummaryData}
                    key={idx}
                  />
                ))}
              </Carousel>
            </div>
          )
        ) : (
          <div className="copilot-content-summaries">
            {!loading &&
              summaryData.map((summary, idx) => (
                <SummaryCard
                  title={summary.title}
                  value={summary.value || 0}
                  linkCaption={summary.linkCaption}
                  icon={summary.icon}
                  iconBkColor={summary.iconBkColor}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  loading={loading}
                  key={idx}
                />
              ))}

            {loading && skeletonCards()}
          </div>
        )}
        {/* </Spin> */}
        <h1 className="copilot-header-title">Customers</h1>
        <div className="copilot-content-body">
          {/* <div className="copilot-content-body-header">
            <div className="copilot-content-body-header-tools">
              
            </div>
          </div> */}
          <div className="copilot-content-body-content">
            {(loading || loadingFilter) && (isTablet || isMobile) ? (
              skeletonCheckinCards()
            ) : !loading &&
              !loadingFilter &&
              customers &&
              customers.length > 0 ? (
              customers.map((customer, idx) => {
                return (
                  <CustomerDataBox
                    customer={customer}
                    prevCustomer={idx > 0 && customers[idx - 1]}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      // setSelectedItem(customer);
                      dispatch(getCustomerData(customer.id));
                    }}
                    isMobile={isMobile}
                    index={idx}
                    key={idx}
                    loading={loadingCustomers}
                  />
                );
              })
            ) : width < 1025 ? (
              <Card>
                <Empty />
              </Card>
            ) : (
              ""
            )}
            {/* {(loading || loadingFilter) &&
              (isTablet || isMobile) &&
              skeletonCheckinCards()}

            {!loading && !loadingFilter && customers && customers.length > 0 ? (
              customers.map((customer, idx) => {
                return (
                  <CustomerDataBox
                    customer={customer}
                    prevCustomer={idx > 0 && customers[idx - 1]}
                    onClick={() => {
                      setIsDrawerOpen(true);
                      // setSelectedItem(customer);
                      dispatch(getCustomerData(customer.id));
                    }}
                    isMobile={isMobile}
                    index={idx}
                    key={idx}
                    loading={loadingCustomers}
                  />
                );
              })
            ) : width < 1025 ? (
              <Card>
                <Empty />
              </Card>
            ) : (
              ""
            )} */}
            {/* <Spin spinning={loadingCustomers}> */}
            <CheckInsList
              // loading={true}
              loading={loading || loadingFilter}
              data={dataCheckins}
              onOpenPopup={(id) => {
                setIsDrawerOpen(true);
                dispatch(getCustomerData(id));
              }}
            />
          </div>
     
        </div>
      </div>
    </div>
  );
};

export default CoPilot;
