import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import "./style.scss";
import { saveCode, sendCode} from "../../../redux/actions/auth";
import { MuiOtpInput } from "mui-one-time-password-input";

const Verification = (props) => {
  const { handleForgotPassword, email, newPassword, error, loading } = props;
  const dispatch = useDispatch();
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [verifyClicked, setVerifyClicked] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (otp.length === 6) {
      setIsOtpValid(true);
    } else {
      setIsOtpValid(false);
    }
  }, [otp]);


  const handleVerifyCode = () => {
    if (isOtpValid) {
      setVerifyClicked(false);
      dispatch(saveCode(otp));
      setVerifyClicked(true);
    }
  };

  useEffect(() => {
    if (newPassword.code != null && !loading && error===null) {
      handleForgotPassword();
    }
  }, [newPassword.code, loading, error]);

  const handleResend = () => {
    dispatch(sendCode(email));
  };

  return (
    <Box className="verification">
      <Typography variant="body1" className="formTitle">
        Verification
      </Typography>
      <Typography
        variant="body1"
        className="formHead"
        sx={{
          marginTop: "20px",
        }}
      >
        Enter verification code
      </Typography>

      <Box className="otpBox">
        <MuiOtpInput
          autoFocus
          length={6}
          value={otp}
          onChange={(e) => setOtp(e)}
          validateChar={() => true}
          TextFieldsProps={{ variant: "standard" }}
        />
      </Box>

      {verifyClicked && !isOtpValid && (
        <Typography variant="caption" color="error">
          Code is 6 characters long.
        </Typography>
      )}

      {error !== "Invalid token specified" && error && verifyClicked && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}


      <Typography variant="body1" className="subText" onClick={handleResend}>
        Didn't receive a code? <span className="link">Resend</span>
      </Typography>

      <Button
        variant="contained"
        className="verificationButton"
        onClick={handleVerifyCode}
      >
        Verify
      </Button>
    </Box>
  );
};

export default Verification;
