import React from "react";
import "./style.scss";
import moment from "moment";
import { Table } from "antd";
import { Icon } from "@iconify/react";
import EyeIcon from "@iconify-icons/streamline/interface-edit-view-eye-eyeball-open-view";
import SkeletonTable from "./SkeletonTable";
import useWindowSize from "../../hooks/useWindowSize";
import UserImg from "../../assets/img/user1.png";

const CheckInsList = ({ data, onOpenPopup, loading }) => {
  const { width } = useWindowSize();

  const columns = [
    {
      title: "DATE/TIME",
      dataIndex: "time",
      key: "time",
      // sorter: (a, b) => new Date(a) - new Date(b),
      // defaultSortOrder: ['descend'],
      render: (time) => {
        let date = moment.utc(time).local();
        let checkValid = date.isValid();
        if (!checkValid) {
          return "--";
        }
        let showTime = "";
        const _currentYear = moment().year();
        const _year = moment(time).year();
        const _currentMonth = moment().month();
        const _month = moment(time).month();
        const _currentWeek = moment().week();
        const _week = moment(time).week();
        const _currentDay = moment().date();
        const _day = moment(time).date();

        if (_currentYear > _year) {
          showTime = moment.utc(time).local().format("MMM DD, YYYY");
        } else if (_currentMonth > _month) {
          showTime = moment.utc(time).local().format("MMM D");
        } else if (_currentDay === _day) {
          // showTime = moment.utc(time).local().format("H:mm");
          showTime = moment.utc(time).local().format("H:mm a");
        } else if (_currentWeek === _week) {
          showTime = moment.utc(time).local().format("dddd");
        } else if (_currentWeek > _week) {
          showTime = moment.utc(time).local().format("MMM D");
        } else if (_currentDay - 1 === _day) {
          showTime = "Yesterday";
        } else {
          showTime = moment.utc(time).local().format("MMM D");
        }

        return showTime;
      },
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: width > 2400 ? "25%" : "30%",
      render: (name) => (
        <p className="user-name">
          {/* <UserOutlined className="user-icon" /> */}
          <img className="avatar" alt="" src={UserImg} />
          <span className="name" title={name}>
            {name}
          </span>
        </p>
      ),
    },
    {
      title: "PERSONA",
      dataIndex: "persona",
      key: "persona",
      render: (persona) => {
        if (persona === "N/A") {
          return <p className="persona unknown">Unknown</p>;
        } else {
          return (
            <p
              className={`persona ${persona
                .toLowerCase()
                .split(" ")
                .map((word) => {
                  return word.replace(/-/g, " ");
                })
                .join("-")}`}
            >
              {persona}
            </p>
          );
        }
      },
    },
    {
      title: "POWER",
      dataIndex: "power",
      key: "power",
      render: (power) => {
        if (power !== "N/A") {
          return power;
        } else {
          return "0%";
        }
      },
    },
    {
      title: "TXN ID",
      dataIndex: "txn_id",
      key: "txn_id",
      render: (txn_id) => txn_id || "--",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (id) => (
        <Icon
          className="view-icon"
          icon={EyeIcon}
          onClick={() => onOpenPopup(id)}
        />
      ),
    },
  ];

  return (
    <SkeletonTable loading={loading} columns={columns}>
      <Table
        rowKey={(record) => record.id}
        className="checkIns"
        columns={columns}
        dataSource={data}
        size="small"
        pagination={{
          position: ["bottomCenter"],
        }}
      />
    </SkeletonTable>
  );
};

export default CheckInsList;
