import { Box, Button, Chip, Grid, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import "../../../pages/Reports/style.scss";
import "./style.scss";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Col, Row } from "antd";
import noRecommendationImage from "../../../assets/img/noRecommendationImage.svg";

const Recommendations = (props) => {
  const { name, image, description, price } = props;
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 900 });

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 2570 });
  const isWideDesktop = useMediaQuery({ minWidth: 2560 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // const skeletonCard = () => {
  //   return (
  //     <Box className={!isMobile? "segmentBio": ""} sx={{ p: 3 }} style={{backgorundColor:"#f8f8f8"}}>
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "row",
  //           justifyContent: "space-between",
  //           marginBottom: "28px",
  //         }}
  //       >
  //         <Box>
  //         <Grid item xl={12} lg={12} sm={5} xs={12}>
  //           <Typography variant="h6" className="blue">
  //             <Skeleton className="skeletonHeading" active />
  //           </Typography>
  //           <Typography
  //             variant="body2"
  //             className="dark-gray"
  //             sx={{ paddingBottom: 2 }}
  //           >
  //             <Skeleton className="skeletonText" active />
  //           </Typography>
  //         </Grid>
  //         </Box>
  //       </Box>
  //       <Box>
  //         <Grid container spacing={1} alignItems="stretch">

  //           <Grid item xl={12} lg={12} sm={5} xs={12}>
  //             <Skeleton className="skeletonBadge" style={{width:"auto", height:"50px"}} active />
  //             <Skeleton className="skeletonBadge" style={{width:"auto", height:"50px"}} active />
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              borderRadius: "10px",
              paddingBottom: "20px",
              height: "auto",
              maxWidth: "500px",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                objectFit: "cover",
                margin: "auto",
                maxHeight: "200px",
                width: "100%",
              }}
              alt="Auto Detailing"
              image={
                "https://images.pexels.com/photos/170811/pexels-photo-170811.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              }
            />
            <CardContent sx={{ textAlign: "center" }}>
              <Typography
                variant="body2"
                className="blue bold-700"
                style={{ fontSize: "14px" }}
              >
                <span>{name}</span>
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  textAlign: "left",
                  fontFamily: "Gotham",
                }}
              >
                Text of the printing and typesetting industry. Lorem Ipsum has
                been the industry's standard dummy text.
              </Typography>
            </CardContent>
            <CardActions>
              <Typography
                variant="body1"
                className="blue bold-700"
                style={{
                  fontSize: isMobile ? "10px" : "12px",
                  marginLeft: "8px",
                }}
              >
                <span>${price} </span>
              </Typography>
              <Button
                variant="contained"
                style={{
                  fontSize: "10px",
                  justifyContent: "flex-end",
                  marginLeft: "auto",
                  borderRadius: "7px",
                  padding: isMobile ? "5px 5px" : "7px 9px",
                }}
              >
                Recommend
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Recommendations;
