import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Typography,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleLogo from "../../../assets/img/google-icon.svg";
import { useMediaQuery } from "react-responsive";
import "./style.scss";
import { login } from "../../../redux/actions/auth";

const LoginForm = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const {
    handleForgotPassword,
    error,
    handleGoogleSignIn,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberInfo, setRememberInfo] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoginClicked(false);
      dispatch(
        login({
          email: values.email,
          password: values.password,
          setLoginClicked: setLoginClicked,
        })
      );
    },
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignUp = () => {
    navigate("/create-account");
  };

  return (
    <Box className="loginForm">
      <Typography variant="body1" className="formHead">
        Username
      </Typography>
      <TextField
        className="textField"
        placeholder="name@company.com"
        variant="outlined"
        name="email"
        sx={{
          marginBottom: 1,
          width: isMobile ? "365px" : "445px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: formik.values.email ? "#0050bc" : "",
              borderRadius: "5px",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: isMobile ? "10px" : "1rem", // Adjust the size based on screen width
          },
        }}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="body1" className="formHead">
          Password
        </Typography>
        <Typography
          variant="body1"
          className="forgotPassword"
          onClick={handleForgotPassword}
        >
          Forgot Password
        </Typography>
      </Box>
      <TextField
        className="textField"
        name="password"
        placeholder="Password"
        variant="outlined"
        sx={{
          marginBottom: 1,
          width: isMobile ? "365px" : "445px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: formik.values.password ? "#0050bc" : "",
              borderRadius: "5px",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: isMobile ? "10px" : "1rem", // Adjust the size based on screen width
          },
        }}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                aria-label="toggle password visibility"
                edge="end"
                sx={{
                  paddingRight: isMobile ? "18px" : "8px", // Adjust padding for button size
                  "& .MuiSvgIcon-root": {
                    fontSize: isMobile ? "1rem" : "1.5rem", // Resize icon itself
                  },
                }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                width: isMobile ? "15px" : "20px", // Adjust width based on screen size
                height: isMobile ? "15px" : "20px", // Adjust height based on screen size
                "& .MuiSvgIcon-root": {
                  fontSize: isMobile ? "15px" : "", // Change icon size inside the checkbox
                },
              }}
              checked={rememberInfo}
              onChange={(event) => setRememberInfo(event.target.checked)}
            />
          }
          label={
            <Typography
              variant="body1"
              className="formCondition"
              sx={{
                fontSize: isMobile ? "12px" : "1rem", // Adjust font size based on screen size
                marginLeft: isMobile ? "2px" : "3px", // Adjust margin-left
                marginRight: isMobile ? "10px" : "", // Adjust margin-right
              }}
            >
              Remember information
            </Typography>
          }
          sx={{
            marginTop: isMobile ? "10px" : "",
            marginLeft: isMobile ? "2px" : "3px", // Margin for the whole FormControlLabel component
            marginRight: isMobile ? "10px" : "", // Adjust right margin
          }}
        />

        {error !== "Invalid token specified" && error && loginClicked && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minWidth: "max-content",
        }}
      >
        <Button
          variant="contained"
          className="loginButton"
          onClick={formik.handleSubmit}
          // sx={{
          //   width: isMobile ? '365px' : 'auto',   // Set width to 365px on mobile
          //   height: isMobile ? '40px' : 'auto',   // Set height to 40px on mobile

          // }}
        >
          Login
        </Button>
        <div>
          <Typography variant="body1" className="signUp" onClick={handleSignUp}>
            Don't have an account? <span className="link">Sign Up</span>
          </Typography>
          <Typography variant="body1" className="orLine">
            or continue with
          </Typography>
          <Box className="ssoButtons">
            <Button onClick={handleGoogleSignIn}>
              <img src={GoogleLogo} alt="Google Logo" />
              <Typography variant="body1" className="Signin-with-google-text">
                Sign in with Google
              </Typography>
            </Button>
            {/* <Button
        onClick={handleMicrosoftSignIn}
        >
          <img src={MicrosoftLogo} alt="Microsoft Logo" />
        </Button> */}
            {/* <Button
        // onClick={() => handleConnectQuickBooks()}
        >
          <img src={QuickbooksLogo} alt="Quickbooks Logo" />
        </Button> */}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default LoginForm;
