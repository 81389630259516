import { useEffect, useState } from "react";
import { Modal, Alert, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentTransactions } from "../../../../redux/actions/payment";
import { hide } from "../../../../redux/actions/modals";
import "./style.scss";
import CancelSubscriptionstep2 from "./CancelSubscriptionstep2";
// import { useDispatch, useSelector } from "react-redux";
import { changePlan } from "../../../../redux/actions/subscriptionManagement";
const WarningIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.375L6.25 7.125L8.5 9.375L9.375 8.5L7.125 6.25L9.375 4L8.5 3.125L6.25 5.375L4 3.125L3.125 4L5.375 6.25L3.125 8.5L4 9.375ZM6.25 12.5C5.38542 12.5 4.57292 12.3358 3.8125 12.0075C3.05208 11.6792 2.39063 11.234 1.82813 10.6719C1.26563 10.1098 0.820418 9.44833 0.492501 8.6875C0.164584 7.92667 0.000417458 7.11417 7.91139e-07 6.25C-0.000415876 5.38583 0.163751 4.57333 0.492501 3.8125C0.821251 3.05167 1.26646 2.39021 1.82813 1.82813C2.38979 1.26604 3.05125 0.820833 3.8125 0.4925C4.57375 0.164167 5.38625 0 6.25 0C7.11375 0 7.92625 0.164167 8.6875 0.4925C9.44875 0.820833 10.1102 1.26604 10.6719 1.82813C11.2335 2.39021 11.679 3.05167 12.0081 3.8125C12.3373 4.57333 12.5013 5.38583 12.5 6.25C12.4988 7.11417 12.3346 7.92667 12.0075 8.6875C11.6804 9.44833 11.2352 10.1098 10.6719 10.6719C10.1085 11.234 9.44708 11.6794 8.6875 12.0081C7.92792 12.3369 7.11542 12.5008 6.25 12.5Z"
      fill="#DE2B2B"
    />
  </svg>
);

const CancelSubscriptionModal = () => {
  const dispatch = useDispatch();
  const {
    accountInfo: { user_id },
  } = useSelector((state) => state.settings);

  const [showCancelStep2, setShowCancelStep2] = useState(false);

  const handleHide = () => {
    dispatch(hide());
  };

  const handleContinue = () => {
    setShowCancelStep2(true);
  };

  useEffect(() => {
    if (user_id) {
      dispatch(getPaymentTransactions({ user_id }));
    }
  }, [user_id]);


    const { subscriptionEndDate, selectedPlan, currentPlan, plansData, currentProduct } = useSelector(
      (state) => state.subscriptionManagement
    );
  
    // Debugging: Log the data
    console.log('Product:', currentProduct?.name);
  
  console.log("currentProduct:", currentProduct);
  console.log("selectedPlan:", selectedPlan);
  console.log("currentPlan:", currentPlan);
  console.log("plansData:", plansData);

  const plan = plansData?.find((p) => String(p.id) === String(selectedPlan)); 
   if (showCancelStep2) {
    return <CancelSubscriptionstep2 />;
  }

  return (
    <Modal
      centered
      open
      onCancel={handleHide}
      
      className="cancel-subscription-modal"
      footer={null}
    >
      <div className="cancel-subscription-container" >
        <div style={{ fontSize: "12px",fontWeight:"600",color: "#DE2B2B" }}>CANCEL PLAN</div>
        <div style={{ 
  fontWeight: "500", 
  fontFamily: "gotham", 
  fontSize: "20px", 
  marginBottom: "20px", 
}}>
  <div style={{lineHeight:"1.3"}}>
  <span >Are you sure you want</span>
  <br />
  <span>to cancel your subscription?</span>
</div></div>
        <Alert
          style={{ fontSize: "14px",fontWeight:"500", fontFamily: "gotham", marginBottom: "20px",padding:"19px",paddingLeft:"13px",border:"1px solid #DE2B2B",borderLeft:"6px solid #DE2B2B",backgroundColor:"#de2b2b24",borderRadius:"7px"}}
          message={
            <div style={{ display: "flex",lineHeight:"1.3" }}>
              <div style={{paddingTop:"5px",paddingRight:"7px",}}>
                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 19L11 0L22 19H0ZM11 16C11.2833 16 11.521 15.904 11.713 15.712C11.905 15.52 12.0007 15.2827 12 15C11.9993 14.7173 11.9033 14.48 11.712 14.288C11.5207 14.096 11.2833 14 11 14C10.7167 14 10.4793 14.096 10.288 14.288C10.0967 14.48 10.0007 14.7173 10 15C9.99933 15.2827 10.0953 15.5203 10.288 15.713C10.4807 15.9057 10.718 16.0013 11 16ZM10 13H12V8H10V13Z" fill="#DE2B2B"/>
                </svg>
              </div>
              <div style={{ paddingLeft: "10px", fontWeight: "500" }}>
                If you cancel your subscription, recurring billing will stop but you’ll have access to your account until February 3, 2025.
              </div>
            </div>
          }
          type="error"
        />




 
<div style={{ marginTop: "10px", lineHeight: "1.5", fontSize: "14px", fontFamily: "Plus Jakarta Sans", fontWeight: "500" }}>
  <p key={plan?.id} style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "10px" }}>
    Cancelling your subscription to the 
    <span style={{ marginLeft:"5px",fontWeight: "bold" }}>
      {currentProduct?.name === "Business/Month"
        ? "monthly Business Plan"
        : currentProduct?.name === "Free/Month"
        ? "monthly Free Plan"
        : currentProduct?.name === "Premium/Month"
        ? "monthly Premium Plan"
        : null}
    </span> 
    <span sx={{marginLeft:"5px"}}>   will:</span>
   
  </p>


     {/* ))} */}
          <div style={{ display: "-webkit-inline-box", alignItems: "center" }}>
          <div style={{ marginTop: "10px" }}>
  <WarningIcon />
</div>

     
            <p style={{ marginBottom:"8px",marginLeft: "20px",marginTop:"8px"}}>Lock you and your team out of your account at the end of your subscription period.</p>
          </div>
          <div style={{ alignItems: "center", display: "flex", marginBottom: "8px" }}>
            <WarningIcon />
            <p style={{ marginLeft: "20px" }}>Deleted data and data insights.</p>
          </div>
          <div style={{ alignItems: "center", display: "flex", marginBottom: "8px" }}>
            <WarningIcon />
            <p style={{ marginLeft: "20px" }}>Disallow enriching of customer profiles.</p>
          </div>
          <div style={{ alignItems: "center", display: "flex", marginBottom: "8px" }}>
            <WarningIcon />
            <p style={{ marginLeft: "20px" }}>Downgrade to the free plan.</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "40px",marginBottom:"10px"}}>
          <Button
            onClick={handleHide}
            style={{
              backgroundColor: "#999999",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
              fontFamily: "gotham",
              borderRadius: "30px",
              marginRight: "25px",
              width:"150px",
              height:"43px",
              paddingTop:"5px"
            }}
          >
            Dismiss
          </Button>
          <Button
            onClick={handleContinue}
            style={{
              backgroundColor: "#DE2B2B",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "16px",
              fontFamily: "gotham",
              borderRadius: "30px",
              width:"150px",
              height:"43px",
               paddingTop:"5px"
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
