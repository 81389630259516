import axios from "axios";
import {
  GET_CONNECTIONS_REQUEST,
  GET_CONNECTIONS_REQUEST_FAIL,
  GET_CONNECTIONS_REQUEST_SUCCESS,
  SHOW_LAUNCH_BUTTON
} from "../constant/connections";

export const getConnectionData = () => async (dispatch) => {
  try {
    const url = window.location.href;
    const hasCode = url.includes("?code=");

    const databillityStr = localStorage.getItem("databillity");
    const databillity = databillityStr ? JSON.parse(databillityStr) : null;
    if (hasCode) {
      const newUrl = url.split("?code=");
      window.history.pushState({}, null, newUrl[0]);
      //const newUrlCode = newUrl.split('&state');

      const requestData = {
        code: newUrl[1],
        url: url,
      };
      console.log(requestData);
      console.log('databillity: ', databillity, databillity.databillity_id)
      try {
        axios
          .post(
            `${process.env.REACT_APP_CONNECTIONS_API_URL}/dev/sync`,
            {
              user_id: "test1",
              realm_id: newUrl[1],
              qbo_uri: url,
              databillity_id:
                (databillity && databillity.databillity_id) || null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*", // Required for CORS support to work
                "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
              },
            }
          )
          .then(function (response) {
      
            if (response.data.code) {
           
            } else {
    
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      } catch (err) {
        console.error(err);
      }
    }

    dispatch({ type: GET_CONNECTIONS_REQUEST });
    const { data } = await axios.get(
       `${process.env.REACT_APP_CONNECTIONS_API_URL}/dev/connections`
    );
    dispatch({ type: GET_CONNECTIONS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_CONNECTIONS_REQUEST_FAIL, payload: error });
  }
};
export const showLaunchButton = (isVisible) => (dispatch) => {
  dispatch({
    type: SHOW_LAUNCH_BUTTON,
    payload: isVisible,
  });
};