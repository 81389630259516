import axios from "axios";
import {
  CREATE_ACCOUNT_SSO,
  CREATE_ACCOUNT_SSO_SUCCESS,
  CREATE_ACCOUNT_SSO_ERROR,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  GET_BASIC_INFO,
  GET_BASIC_INFO_SUCCESS,
  GET_BASIC_INFO_FAIL,
  SET_BASIC_INFO,
  SET_BASIC_INFO_SUCCESS,
  SET_BASIC_INFO_FAIL,
  SET_COMPANY_INFO,
  SET_COMPANY_INFO_SUCCESS,
  SET_COMPANY_INFO_FAIL,
  SET_LOCATION_INFO,
  SET_LOCATION_INFO_SUCCESS,
  SET_LOCATION_INFO_FAIL,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_SESSION_FAIL,
  UPDATE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_STEP_SUCCESS,
  UPDATE_ONBOARDING_STEP_FAIL,
  CONFIRM_USER,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_FAIL,
  SKIPPED_INFO_SUCCESS,
  SKIPPED_INFO_ERROR
} from "../constant/onboarding";
import { loadUser } from "./auth";
import { LOGIN_REQUEST_SUCCESS } from "../constant/auth";
import jwtDecode from "jwt-decode";

export const skipInfo = () => async (dispatch) => {
  try {
    const email=  localStorage.getItem("email");
    await axios.post(
     `${process.env.REACT_APP_ONBOARDING_API_URL}/api/skip-company-information`,
      {
        email:email
      }
     ); 
     dispatch({
       type: UPDATE_ONBOARDING_STEP_SUCCESS,
       payload: 'at_stripe',
     });
 
   } catch (error) {
     dispatch({
       type: SET_COMPANY_INFO_FAIL,
       payload: error.message,
     });
   }
}
export const onboardingStep = (email) => async (dispatch) => {
  dispatch({
    type: UPDATE_ONBOARDING_STEP,
  });
  const tenantID = localStorage.getItem("tenantID");
  const userIdSub = localStorage.getItem("userIDSub");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/onboarding-status`,
      {
        params: {
          email: email,
        },
        headers: {
          "tenantId": tenantID,
          "userIdSub": userIdSub
        }
      }
    );
    localStorage.setItem("onboardingrole",response.data.roles);
    localStorage.setItem("country",response.data.country);
    dispatch({
      type: UPDATE_ONBOARDING_STEP_SUCCESS,
      payload: response.data.onboarding_status,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ONBOARDING_STEP_FAIL,
      payload: error.message,
    });
  }
};

export const createAccountSSO =
  (id_token, access_token, refresh_token, navigate) => async (dispatch) => {
    dispatch({
      type: CREATE_ACCOUNT_SSO,
    });

    try {
      // const accessToken = await findTokenAsync("accessToken");
      // const idToken = await findTokenAsync("idToken");
      // const refreshToken = await findTokenAsync("refreshToken");

      localStorage.setItem("id_token", id_token);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("databillity_token", access_token);
      const decodedToken = jwtDecode(id_token);
      const email = decodedToken.email;

      dispatch({
        type: CREATE_ACCOUNT_SSO_SUCCESS,
        payload: {
          email: email,
          provider: decodedToken.identities[0].providerName,
        },
      });

      dispatch({
        type: LOGIN_REQUEST_SUCCESS,
        payload: {
          email: email,
          token: access_token,
          refresh_token,
        },
      });

      // Dispatch action to load user data
      dispatch(loadUser(id_token));

      // Check if user exists in db
      if (decodedToken.identities[0].providerName !== null)
        dispatch(confirmUser(email, navigate));
    } catch (error) {
      dispatch({
        type: CREATE_ACCOUNT_SSO_ERROR,
        payload: error.message,
      });
    }
  };

export const confirmUser = (email, navigate) => async (dispatch) => {
  dispatch({
    type: CONFIRM_USER,
  });

  try {
    // Get onboarding step
    localStorage.setItem("email", email);

    const response = await axios.post(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/handle-sso`,
      {
        email: email,
      }
    );
    if (response && response.data) {
      // Setting data in local storage
      localStorage.setItem("tenantID", response.data.tenant_id || '');
      localStorage.setItem("userIDSub", response.data.user_id || '');

      // Dispatching the onboarding step action
      await dispatch(onboardingStep(email));
    }

    dispatch({
      type: CONFIRM_USER_SUCCESS,
      payload: true,
    });
  } catch (error) {
    dispatch({
      type: CONFIRM_USER_FAIL,
      payload: { verified: false, error: error.response.data.error },
    });
    // navigate("/login"); // account verification failed
  }
};

export const createAccount = (formdata) => async (dispatch) => {
  const { email, password, setActiveStep } = formdata;
localStorage.setItem("email",email);
  dispatch({
    type: CREATE_ACCOUNT_REQUEST,
  });

  try {
    await axios.post(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/signup`,
      { email, password }
    );

    dispatch({
      type: CREATE_ACCOUNT_SUCCESS,
      payload: email,
    });

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    // dispatch(setNotification("success", response.data));
  } catch (error) {
    dispatch({
      type: CREATE_ACCOUNT_ERROR,
      payload: error.response.data.error || error.response.data.message,
    });
  }
};

export const verifyOTP = (formdata) => async (dispatch) => {
  dispatch({
    type: VERIFY_OTP_REQUEST,
  });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/verify-signup-code`,
      formdata
    );


    localStorage.setItem("tenantID", response?.data?.tenant_id);
    localStorage.setItem("userIDSub", response?.data?.user_id);
    localStorage.setItem("verified", true); 
    dispatch({
      type: VERIFY_OTP_SUCCESS,
      payload: true,
    });

    // dispatch(setNotification("success", response.data));
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_ERROR,
      payload: error.message,
    });
  }
};

export const resendCode = (email) => async (dispatch) => {
  dispatch({
    type: RESEND_CODE_REQUEST,
  });

  try {
    await axios.post(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/resend-verification-code`,
      email
    );

    dispatch({
      type: RESEND_CODE_SUCCESS,
    });

    // dispatch(setNotification("success", response.data));
  } catch (error) {
    dispatch({
      type: RESEND_CODE_ERROR,
      payload: error.message,
    });
  }
};

export const getBasicInfo = (email) => async (dispatch) => {
  dispatch({
    type: GET_BASIC_INFO,
  });
  const tenantID = localStorage.getItem("tenantID");
  const userIdSub = localStorage.getItem("userIDSub");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/get-basic-information`,
      {
        params: {
          email: email,

        },
        headers: {
          "tenantId": tenantID,
          "userIdSub": userIdSub
        }
      }
    );

    dispatch({
      type: GET_BASIC_INFO_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BASIC_INFO_FAIL,
      payload: error.message,
    });
  }
};

export const setBasicInfo = (basicInfo) => async (dispatch) => {
  dispatch({
    type: SET_BASIC_INFO,
  });
  const tenantID = localStorage.getItem("tenantID");
  const userIdSub = localStorage.getItem("userIDSub");
  try {
 await axios.post(
  `${process.env.REACT_APP_ONBOARDING_API_URL}/api/basic-information`,
      basicInfo,
      {
        headers: {
          "tenantId": tenantID,
          "userIdSub": userIdSub
        },
      }
    );

    dispatch({
      type: SET_BASIC_INFO_SUCCESS,
      payload: basicInfo,
    });

    dispatch(onboardingStep(basicInfo.email));
  } catch (error) {
    dispatch({
      type: SET_BASIC_INFO_FAIL,
      payload: error.message,
    });
  }
};

export const setCompanyInfo = (companyInfo) => async (dispatch) => {
  dispatch({
    type: SET_COMPANY_INFO,
  });
  const tenantID = localStorage.getItem("tenantID");
  const userIdSub = localStorage.getItem("userIDSub");

  try {
   await axios.post(
    `${process.env.REACT_APP_ONBOARDING_API_URL}/api/company-information`,
      companyInfo, 
      {
        headers: {
          "tenantId": tenantID,
          "userIdSub": userIdSub
        },
      }

    );

    dispatch({
      type: SET_COMPANY_INFO_SUCCESS,
      payload: companyInfo,
    });

    dispatch(onboardingStep(companyInfo.email));
  } catch (error) {
    dispatch({
      type: SET_COMPANY_INFO_FAIL,
      payload: error.message,
    });
  }
};


export const setLocationInfo = (locationInfo) => async (dispatch) => {
  dispatch({
    type: SET_LOCATION_INFO,
  });
  const tenantID = localStorage.getItem("tenantID");
  const userIdSub = localStorage.getItem("userIDSub");

  try {
   await axios.post(
    `${process.env.REACT_APP_ONBOARDING_API_URL}/api/company-location-information`,
      locationInfo, 
      {
        headers: {
          "tenantId": tenantID,
          "userIdSub": userIdSub
        },
      }

    );

    dispatch({
      type: SET_LOCATION_INFO_SUCCESS,
      payload: locationInfo,
    });

    dispatch(onboardingStep(locationInfo.email));
  } catch (error) {
    dispatch({
      type: SET_LOCATION_INFO_FAIL,
      payload: error.message,
    });
  }
};






export const getProductList = (planType) => async (dispatch) => {
  dispatch({
    type: GET_PRODUCT_LIST,
  });
  const country = localStorage.getItem('country');// Default to USD if not set
  let currency = "CAD";
    if (country === "United States"){
      currency="USD"
    }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/products`,
      {
        params: {
          currencyType: currency,
          planType: planType
        }
      }
    );

    dispatch({
      type: GET_PRODUCT_LIST_SUCCESS,
      payload: response.data.products,
    });
  } catch (error) {
    dispatch({
      type: GET_PRODUCT_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const createCheckoutSession = (planInfo) => async (dispatch) => {
  dispatch({
    type: CREATE_CHECKOUT_SESSION,
  });
  const tenantID = localStorage.getItem("tenantID");
  const userIdSub = localStorage.getItem("userIDSub");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ONBOARDING_API_URL}/api/create-checkout-session`,
      planInfo,
      {
        headers: {
          "tenantId": tenantID,
          "userIdSub": userIdSub
        },
      }
        );

    dispatch({
      type: CREATE_CHECKOUT_SESSION_SUCCESS,
      payload: {
        ...planInfo,
        url: response.data.session.url,
      },
    });

    window.location.href = response.data.session.url;
  } catch (error) {
    dispatch({
      type: CREATE_CHECKOUT_SESSION_FAIL,
      payload: error.message,
    });
  }
};
