import axios from "axios";
import {
  GET_CURRENT_PRODUCT_SUCCESS,
  GET_CURRENT_PRODUCT_ERROR,
  CHANGE_PLAN_SUCCESS,
  CHANGE_PLAN_ERROR,
  GET_PLANS_SUCCESS,
} from "../constant/subscriptionManagement";

export const getCurrentProduct = () => async (dispatch) => {
  try {
    let userIdSub = localStorage.getItem("userIDSub");
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/current-product`,
      {
        params: {
          userId: userIdSub,
        },
      }
    );
    dispatch({ type: GET_CURRENT_PRODUCT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_CURRENT_PRODUCT_ERROR, payload: error });
  }
};

export const changePlan = (newPlan) => async (dispatch) => {
  try {
    let userIdSub = localStorage.getItem("userIDSub");
    const country = localStorage.getItem("country"); // Default to USD if not set
    let currency = "CAD";
    if (country === "United States") {
      currency = "USD";
    }

    // let tenantID = localStorage.getItem("tenantID");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/change-plan`,
      {
        userId: userIdSub,
        newPlan: newPlan,
        currency: currency,
      }
    );

    dispatch({ type: CHANGE_PLAN_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CHANGE_PLAN_ERROR, payload: error });
  }
};

export const plansDataCurrency = () => async (dispatch) => {
  const plansData = [
    {
      id: 1,
      name: "Free",
      description: "Ideal for solo operators and businesses with one location.",
      price: "$0",
      detailsUrl: "#",
    },
    {
      id: 2,
      name: "Business",
      description:
        "Right-sized for multi-location and omni channel businesses.",
      price: "$199",
      detailsUrl: "#",
    },
    {
      id: 3,
      name: "Premium",
      description: "Perfect fit for growth oriented companies.",
      price: "$799",
      detailsUrl: "#",
    },
  ];
  const getExchangeRate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_EXCHANGE_RATE_API_URL}/USD`
      );

      const rate = response.data.rates["CAD"]; // Get the USD to CAD conversion rate
      const updatedPlans = plansData.map((plan) => {
        if (plan.price !== "$0") {
          const priceNumber = parseFloat(plan.price.substring(1));
          // Remove the dollar sign and convert to number
          const convertedPrice = Math.round(priceNumber * rate);
          return { ...plan, price: `$${convertedPrice}` };
        }
        return plan;
      });
      dispatch({ type: GET_PLANS_SUCCESS, payload: updatedPlans });
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };
  const country = localStorage.getItem("country"); // Default to USD if not set
  let currency = "CAD";
  if (country === "United States") {
    currency = "USD";
    //  setLoading(false)
  }
  if (currency === "CAD") {
    // Only convert if the current currency is USD
    getExchangeRate();
  } else {
    dispatch({ type: GET_PLANS_SUCCESS, payload: plansData });
  }
};
