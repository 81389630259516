import React, { useState, useEffect } from "react";
import "./style.scss";
import { UserOutlined } from "@ant-design/icons";
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid, Skeleton, Stack } from "@mui/material";
import UserAvt from "../../../assets/img/user1.png";
import FormProfile from "./FormProfile";
import UserDetail from "./UserDetail";
import Detail from "./detail";
import { useDispatch, useSelector } from "react-redux";
import { getAccountInfo, countUsers, getAllUsersInfo } from "../../../redux/actions/settings";

const StyledAvatar = styled(Avatar)({
  backgroundColor: deepPurple[500],
  color: '#fff'
});

const FlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: "40px"
});

const SettingProfile = () => {
  const [isEdit, setIsEdit] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((state) => state.settings);
  const [data, setData] = useState([]);
const role= localStorage.getItem("roles")
  useEffect(() => {
    // dispatch(getAccountInfo());
    dispatch(countUsers());
    dispatch(getAllUsersInfo());
  }, [dispatch]);
  const formatPhoneNumber = (phoneNumber) => {
    // Check if the phone number is a valid 10-digit number
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      return phoneNumber; // Return as is if it's not a valid number
    }
    
    // Format the number as 999-999-9999
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  };
  const toTitleCase=(text)=> {
    if(text)
{    return text
      .toLowerCase() // Convert the entire string to lowercase
      .split(' ') // Split the string into an array of words
      .map(word => {
        if (["nw", "sw", "st","us"].includes(word.toLowerCase())) {
          return word.toUpperCase(); // Capitalize fully
        }
        // Otherwise, apply title case to the word
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' '); // Join the words back into a single string
 }
else {
  return "--"
}
}
  useEffect(() => {
    if (accountInfo) {
      const locationLabel = accountInfo.country === "Canada" ? "Province" : "State";
      setData([
        { label: "First name", value: accountInfo.first_name },
        { label: "Last name", value: accountInfo.last_name },
        { label: "Phone Number", value: formatPhoneNumber(accountInfo.phone) },
        { label: "Email Address", value: accountInfo.email },
        { label: "Title", value: accountInfo.job_role },
        { label: "Role", value: accountInfo.roles
        },
        {
          label: "Location",
          value: toTitleCase([
            accountInfo?.address_line_1,
            accountInfo?.address_line_2,
            accountInfo?.city,
            accountInfo?.country
          ]
          .filter(Boolean) 
          .join("   "))
        },
        
        // { label: locationLabel, value: accountInfo.state_province },
      ]);
      setLoading(false);
    }
  }, [accountInfo]);

  const getInitials = (name) => {
    return name ? name.split(' ').map(n => n[0]).join('').toUpperCase() : "";
  };

  return (
    <><h1 className="title" style={{ display: isMobile ? "contents" : "none", marginLeft: isMobile ? "20px" : "" }}>
      Profile
    </h1><div className="settingProfile">


        <FlexContainer>
          <StyledAvatar>{accountInfo?.first_name ? getInitials(accountInfo.first_name) : ""}</StyledAvatar>
          <span style={{ fontWeight: '600' }}>{accountInfo?.first_name + " " + accountInfo?.last_name}</span>
        </FlexContainer>

        {isEdit ? (
          <FormProfile onCancel={() => setIsEdit(false)} accountInfo={accountInfo} />
        ) : loading ? (
          <Skeleton variant="rectangular" height={60} />
        ) : data.length > 0 ? (
          <Detail
            data={data}
            onUpdate={() => setIsEdit(true)}
            onCancel={() => setIsEdit(false)}
            onDelete={() => setIsEdit(false)} />
        ) : null}
      </div></>
  );
};

export default SettingProfile;
