import "./style.scss";
import { Avatar, Button, Modal, Upload, message } from "antd";

import UserAvt from "../../../assets/img/user1.png";

const ModalAvatar = ({ open, type, onClose }) => {
  const props = {
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    accept: "image/*",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {

      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Modal
      wrapClassName="modalAvatar"
      title=""
      centered
      open={open}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      <div className="content">
        <Avatar size={150} src={UserAvt} />
        {type === "user" && (
          <Button className="btnTakePhoto" block>
            <svg
              width="17"
              height="15"
              viewBox="0 0 17 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.7 1.66667H4.25L5.95 0H11.05L12.75 1.66667H15.3C15.7509 1.66667 16.1833 1.84226 16.5021 2.15482C16.8209 2.46738 17 2.89131 17 3.33333V13.3333C17 13.7754 16.8209 14.1993 16.5021 14.5118C16.1833 14.8244 15.7509 15 15.3 15H1.7C1.24913 15 0.81673 14.8244 0.497918 14.5118C0.179107 14.1993 0 13.7754 0 13.3333V3.33333C0 2.89131 0.179107 2.46738 0.497918 2.15482C0.81673 1.84226 1.24913 1.66667 1.7 1.66667ZM8.5 4.16667C7.37283 4.16667 6.29183 4.60565 5.4948 5.38705C4.69777 6.16846 4.25 7.22826 4.25 8.33333C4.25 9.4384 4.69777 10.4982 5.4948 11.2796C6.29183 12.061 7.37283 12.5 8.5 12.5C9.62717 12.5 10.7082 12.061 11.5052 11.2796C12.3022 10.4982 12.75 9.4384 12.75 8.33333C12.75 7.22826 12.3022 6.16846 11.5052 5.38705C10.7082 4.60565 9.62717 4.16667 8.5 4.16667ZM8.5 5.83333C9.1763 5.83333 9.82491 6.09672 10.3031 6.56557C10.7813 7.03441 11.05 7.67029 11.05 8.33333C11.05 8.99637 10.7813 9.63226 10.3031 10.1011C9.82491 10.5699 9.1763 10.8333 8.5 10.8333C7.8237 10.8333 7.1751 10.5699 6.69688 10.1011C6.21866 9.63226 5.95 8.99637 5.95 8.33333C5.95 7.67029 6.21866 7.03441 6.69688 6.56557C7.1751 6.09672 7.8237 5.83333 8.5 5.83333Z"
                fill="#002857"
              />
            </svg>
            Take photo
          </Button>
        )}
        <Upload {...props}>
          <Button className="btnUpload" type="primary" block>
            Upload
          </Button>
        </Upload>
      </div>
    </Modal>
  );
};

export default ModalAvatar;
