import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton
} from "@mui/material";
import Images from "../../../../assets/img/ImgConstants";
import BulletIcon from "../../../../assets/img/marker-icon.svg";
import InfoIcon from "../../../../assets/img/information-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import { InfoIconDescriptions, PlanList } from "../../../../pages/Onboarding/constants";
import { createCheckoutSession } from "../../../../redux/actions/onboarding";
import axios from 'axios';
import "./style.scss";

const PlanCards = (props) => {
  const { isMonthly } = props;
  const dispatch = useDispatch();
  const lastPlan = PlanList.slice(-1)[0];
  const [updatedPlanList, setUpdatedPlanList] = useState("");
  const [plan, setPlan] = useState();
  const [isSelected, setIsSelected] = useState(updatedPlanList[1]?.id);
  const { onboarding } = useSelector(
    (state) => state.onboarding
  );
  const [dialogOpen, setDialogOpen] = useState(false); // Manage dialog state
  const [selectedDescription, setSelectedDescription] = useState(""); // Store selected description
  const [selectedTitle, setSelectedTitle] = useState(""); // Store selected title
  
  useEffect(() => {
    async function fetchExchangeRate() {
      const country = localStorage.getItem('country'); // Default to USD if not set
    let currency = "CAD";
      if (country === "United States"){
        currency = "USD";
      }
      let conversionRate = 1;

      if (currency !== "USD") {
        try {
          const response = await axios.get(
            `https://api.exchangerate-api.com/v4/latest/USD`
          );
          conversionRate = response.data.rates[currency];
        } catch (error) {
          console.error('Error fetching exchange rate:', error);
        }
      }

      const updatedPlans = PlanList.map((plan) => {
        const pricingType = isMonthly ? "Month" : "Year";
        const matchedPlan = onboarding.planInfo.list.find(
          (info) => info.name.includes(plan.title) && info.name.includes(pricingType)
        );
        const priceInUSD = matchedPlan ? matchedPlan.price : plan.price;

        return {
          ...plan,
          price: (priceInUSD)?.toFixed(2), // Convert price to selected currency
          currency: currency, // Add the currency to the plan data if needed
        };
      });

      setUpdatedPlanList(updatedPlans);
    }

    fetchExchangeRate();
  }, [onboarding, isMonthly]);
   
  useEffect(() => {
    plan && handleNextButtonClick();
  }, [plan]);
  const handleDialogOpen = (point) => {
    setSelectedTitle(point);
    setSelectedDescription(InfoIconDescriptions[point]);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNextButtonClick = () => {
    const country = localStorage.getItem('country');// Default to USD if not set
    let currency = "CAD";
      if (country === "United States"){
        currency="USD"
      }
    dispatch(
      createCheckoutSession({
        email: onboarding.account.email,
        plan: `${plan.title}/${isMonthly ? "Month" : "Year"}`,
        planType: `${isMonthly ? "month" : "year"}`,
        currencyType: currency
      })
    );
  };
  const handleContactUsClick = () => {
   // setPlan(lastPlan); 
   window.location.href = "https://www.databillity.com/contact";
  };
  return (
    <Box className="pricePlanCards">
      <Box className="pricePlanTopCards">
        {updatedPlanList &&
          updatedPlanList?.slice(0, -1).map((plan, index) => (
            <Box
              key={plan?.id}
              className={`planCard ${plan?.id === isSelected && `isSelected`}`}
              onClick={() => setIsSelected(plan?.id)}
            >
              <Box className="cardUpperSection">
                {plan?.title === "Business" && (
                  <Chip
                    label="Most Popular Tier Pricing"
                    icon={Images.PRICE_PLAN_ICON}
                    className="cardChip"
                  />
                )}
                <Typography variant="body2" className="cardHeading">
                  {plan?.title}
                </Typography>
                
                <Box className="cardPrice">
                  <Typography variant="h4" className="price">
                    ${isMonthly ? plan?.price : plan?.price}
                  </Typography>
                  <Typography variant="subtitle2" className="pricePer">
                    {isMonthly ? `/month` : `/year`}
                  </Typography>
                </Box>
                <Button variant="contained" onClick={() => setPlan(plan)}>
                  Sign-up
                </Button>
                <Typography variant="body1" className="cardText">
                  {plan?.detail}
                </Typography>
              </Box>
              <Divider className="divider" />
              <Box className="cardLowerSection">
              {Array.isArray(plan?.points) &&
                plan?.points?.map((point, i) => (
                  <Box className="bulletPoint" sx={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: ["1,000 Recommendations", "2,500 Recommendations"].includes(point) ? "nowrap" : "normal", 
                    
                  }}>
                    <img src={BulletIcon} alt="bullet icon" />
                    <Typography variant="body1" className="cardText">
                      {point}
                    </Typography>
                      {InfoIconDescriptions[point] && (
                        <img
                          src={InfoIcon}
                          alt="information icon"
                          onClick={() => handleDialogOpen(point)}
                        />
                      )}
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
      </Box>

      <Box
        className="pricePlanBottomCard"
        onClick={() => setIsSelected(lastPlan.id)}
      >
        <Box className="cardTitle">
          <Typography variant="h5" className="cardHeading">
            {lastPlan.title}
          </Typography>
          <Typography variant="body2" className="cardPoint">
            {lastPlan.points}
          </Typography>
        </Box>
        <Typography variant="body1" className="cardText">
          {lastPlan.detail}
        </Typography>
        <Button variant="contained" onClick={handleContactUsClick}>
          Contact us
        </Button>
      </Box>

      {/* Dialog for displaying description */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: "10px",
          paddingRight:"2.5%",
          borderRadius: "10px",
          maxWidth: "410px",  // Controls the width
          backgroundColor: "#f5f5f5",
        },
      }}
      >
        <DialogContent>
          <DialogActions>
            <IconButton
              onClick={() => handleDialogClose()}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#000",
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <Typography variant="h6" className="dialogHeading" style={{ color: "#02afef",fontSize:"12px",marginLeft:"2%",fontWeight:"500",fontFamily:"Gotham"}}>
            DEFINITION
          </Typography>
          <Typography variant="h6" className="dialogHeading"style={{marginBottom:"5%",marginLeft:"2%",fontWeight:"500",fontSize:"20px",fontFamily:"Gotham"}}>
            {selectedTitle}
          </Typography>
          <Typography variant="body1" style={{marginBottom:"4%",marginLeft:"2%",fontSize:"14px",color:"#002857",fontFamily:"Plus Jakarta Sans"}}>
            {selectedDescription}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PlanCards;
