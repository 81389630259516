import { Modal, Form, Select, Input, Button, Row, Col } from "antd";
import "./style.scss";
import { inviteUserSES } from "../../../redux/actions/settings";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";

const ModalInvite = ({ open, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((state) => state.settings);
  const [formData, setFormData] = useState({
    email: "",
    roles: "Standard User",
  });

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };
  const handleDropdownChange = (open) => {
    setIsOpen(open);
  };
  const handleFinish = () => {
    const emailValue = form.getFieldValue("email");
    const roleValue = form.getFieldValue("roles");
    dispatch(inviteUserSES(emailValue, roleValue));
    onClose();
  };

  return (
    <Modal
      wrapClassName="modalInvite"
      open={open}
      onCancel={onClose}
      title=""
      footer={null}
    >
      <h3 className="title">
        Invite people to {accountInfo?.company_legal_name}
      </h3>

      <Form
        form={form}
        className="formProfile"
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={[54, 15]}>
          <Col xs={24}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Email invalid",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Role"
              name="roles"
              rules={[{ required: true, message: "Role is required" }]}
            >
              <Select
                value={form.getFieldValue("roles")} // Bind the selected role to form state
                onChange={(value) => form.setFieldsValue({ roles: value })} // Update form state when value changes
                suffixIcon={
                  isOpen ? (
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.96478 0.73351C6.02441 0.65791 6.10421 0.59609 6.19729 0.55339C6.29038 0.51068 6.39396 0.48837 6.49912 0.48837C6.60428 0.48837 6.70786 0.51068 6.80094 0.55339C6.89403 0.59609 6.97382 0.65791 7.03346 0.73351L12.8839 8.10974C12.9516 8.19482 12.9913 8.29447 12.9987 8.39787C13.0061 8.50126 12.9809 8.60445 12.9258 8.69622C12.8708 8.78798 12.7879 8.86482 12.6863 8.91838C12.5847 8.97193 12.4683 8.99984 12.3496 9.00002H0.648661C0.53025 9.00003 0.414212 8.97143 0.313027 8.91775C0.211842 8.86407 0.129337 8.78733 0.0743855 8.69568C0.019434 8.60412 -0.00588502 8.50122 0.00115111 8.39805C0.00818723 8.29488 0.0473123 8.19433 0.114319 8.10974L5.96478 0.73351Z"
                        fill="#002857"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="13"
                      height="9"
                      viewBox="0 0 13 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                        fill="#002857"
                      />
                    </svg>
                  )
                }
              >
                <Select.Option value="Standard User">
                  Standard User
                </Select.Option>
                <Select.Option value="Manager">Manager</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className="actions">
              <Button className="btnSave" type="primary" htmlType="submit">
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalInvite;
