import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Container, Grid, Box, CircularProgress } from "@mui/material";
import LogoDesktop from "../../assets/img/logo-desktop.svg";
import LoginGraphic from "../../assets/img/login-desktop-graphic.svg";
import "./style.scss";
import LoginForm from "../../components/Login/LoginForm";
import Footer from "../../components/Layout/Footer";
import Verification from "../../components/Login/Verification";
import NewPassword from "../../components/Login/NewPassword";
import ForgotPassword from "../../components/Login/ForgotPassword";
import { clearStore } from "../../redux/actions/auth";


const Login = () => {
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuthenticated, email, newPassword, loading, error, newPasswordRequired } =
    useSelector((state) => state.auth);
  const { onboarding } = useSelector((state) => state.onboarding);


  useEffect(() => {
    localStorage.clear();
    const loadImages = () => {
      const images = [LogoDesktop, LoginGraphic];
      const promises = images.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      Promise.all(promises)
        .then(() => {
          setImagesLoaded(true);
        })
        .catch((err) => console.error("Failed to load images", err));
    };

    loadImages();
  }, []);
  useEffect(() => {
    // Clear the Redux store when the component mounts
    dispatch(clearStore());
  }, [dispatch]);
  useEffect(() => {
    if (isAuthenticated) {
      if (onboarding.onboardingStep !== 3) navigate("/onboarding");
      else navigate("/customers");
    }
  }, [onboarding.onboardingStep]);

  const [step, setStep] = useState(0);
  const handleForgotPassword = () => {
    setStep((prevStep) => prevStep + 1);
  };
  useEffect(() => {
    if (newPasswordRequired) {
      setStep(3);
    }
  }, [newPasswordRequired])

  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const clientId = process.env.REACT_APP_AWS_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING;
  const responseType = process.env.REACT_APP_RESPONSE_TYPE;
  const scope = "openid profile email";

  // const loginUrlGoogle = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=Google`;
  const loginUrlMicrosoft = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=Microsoft`;

  function handleGoogleSignIn() {
    localStorage.clear();
    sessionStorage.clear();

    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    const state = Math.random().toString(36).substring(2, 15);
    const loginUrlGoogle = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${state}&prompt=select_account`;

    // First, logout from Google
    const googleLogoutUrl = 'https://www.google.com/accounts/Logout';

    // Create a hidden iframe to trigger Google logout
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = googleLogoutUrl;
    document.body.appendChild(iframe);

    // Wait a bit to ensure logout is processed, then redirect to your login URL
    setTimeout(() => {
      document.body.removeChild(iframe);
      window.location.href = loginUrlGoogle;
    }, 2000);

  }
  function handleMicrosoftSignIn() {
    window.location.href = loginUrlMicrosoft;
  }



    if (!imagesLoaded) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      );
    } // Add a spinner or skeleton screen here if preferred



  return (
    <Container maxWidth="xxl" className="login">
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box className="loginContainer">
            <Box className="loginCard">
              <Grid container>
                <Grid item xl={5}>
                  <Box className="loginCardLeftSide">
                    <Box className="loginLogo">
                      <img src={LogoDesktop} alt="logo desktop" />
                    </Box>
                    {step === 0 ? (
                      <LoginForm
                        handleForgotPassword={handleForgotPassword}
                        error={error}
                        loading={loading}
                        handleGoogleSignIn={handleGoogleSignIn}
                        handleMicrosoftSignIn={handleMicrosoftSignIn}
                      />
                    ) : step === 1 ? (
                      <ForgotPassword
                        handleForgotPassword={handleForgotPassword}
                        email={email}
                        error={error}
                        loading={loading}
                        handleGoogleSignIn={handleGoogleSignIn}
                        handleMicrosoftSignIn={handleMicrosoftSignIn}
                        setStep={setStep}
                      />
                    ) : step === 2 ? (
                      <Verification
                        handleForgotPassword={handleForgotPassword}
                        email={email}
                        newPassword={newPassword}
                        error={error}
                        loading={loading}
                      />
                    ) : step === 3 ? (
                      <NewPassword
                        email={email}
                        newPassword={newPassword}
                        error={error}
                        loading={loading}
                        setStep={setStep}
                      />
                    ) : null}
                  </Box>
                </Grid>
                {!isTablet && !isMobile && (
                  <Grid item xl={7}>
                    <Box className="loginCardRightSide">
                      <img
                        src={LoginGraphic}
                        alt="login graphic"
                        className="loginGraphic"
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box style={{ position: "absolute", width: "100%", bottom: "0px" }}>
            <Footer />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;

//  Your username is Kata1 and temporary password is 8ENr6fD?.
