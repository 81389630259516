import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Button } from "antd";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.scss";

const RangePickerCustom = ({ onSubmit, values }) => {
  const today = new Date();
  const [disabled, setDisabled] = useState(false);
  const [dates, setDates] = useState(values);

  const handleChangeDates = (ranges) => {
  
    setDates(ranges.selection);
    if (ranges.selection.startDate && ranges.selection.endDate) {
      setDisabled(false);
    }
  };

  const handleReset = () => {
    let obj = {
      startDate: today,
      endDate: today,
      key: "selection",
    };
    setDates(obj);
    onSubmit(obj);
  };

  const handleSubmit = () => {
    onSubmit(dates);
  };

  return (
    <div className="popupPickerContent">
      <DateRangePicker
        ranges={[dates]}
        onChange={handleChangeDates}
        maxDate={today}
        // staticRanges={[]}
      />
      <div className="actions">
        <Button className="btnReset" onClick={handleReset}>
          Reset
        </Button>
        <Button
          className="btnSubmit"
          type="primary"
          disabled={disabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default RangePickerCustom;
