import { Modal, Form, Input, Button, Row, Col } from "antd";
import "./style.scss";
import { useEffect } from "react";
import { submitUserInfo} from "../../../redux/actions/settings";
import { useDispatch } from "react-redux";

// {
//   "first_name":"Testing", 
//   "last_name":"Testing again",
//    "phone":"090078601", 
//    "job_role": "Test User", 
//    "account_level_role":"Other",
//    "user_id":"a428d4b8-8021-708c-57fe-fc1085e7a6e0"
  
//   }

const ModalUserDetail = ({ detail, open, onClose, selectedUserId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
const role = localStorage.getItem("roles")
  const handleFinish = (values) => {
  values["user_id"]= selectedUserId;
    dispatch(submitUserInfo(values));
    onClose();
  
  };
  const formatPhoneNumber = (phoneNumber) => {
    // Check if the phone number is a valid 10-digit number
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      return phoneNumber; // Return as is if it's not a valid number
    }
    
    // Format the number as 999-999-9999
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  };
  

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        first_name: detail?.first_name,
        last_name: detail?.last_name,
        email: detail?.email,
        phone: formatPhoneNumber(detail?.phone),
        job_role: detail?.job_role,
        account_level_role: detail?.roles
      });
    }
  }, [detail]);

  return (
    <Modal
      wrapClassName="modalInvite modalCreateUser"
      open={open}
      onCancel={onClose}
      title=""
      footer={null}
    >
      <h3 className="title">
        {detail ? "Account detail" : "Create your account"}
      </h3>

      <Form
        form={form}
        className="formProfile"
        layout="vertical"
        onFinish={handleFinish}
      >
        <Row gutter={[54, 15]}>
          <Col md={12}>
            <Form.Item
              label="First name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Last name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Title" name="job_role">
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Role" name="account_level_role" >
       
              <Input placeholder="" defaultValue="" disabled />
         
    
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone number is required",
                },
              ]}
            >
              <Input placeholder="" defaultValue="" />
            </Form.Item>
          </Col>
         
       
          <Col md={24}>
            <div className="actions">
              <Button className="btnSave" type="primary" htmlType="submit">
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalUserDetail;
