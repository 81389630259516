import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Images from "../../../assets/img/ImgConstants";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Rate, Input, Button, Empty } from "antd";
import { getOrAssignAvatar } from "../../../utils/avatar";
import "../../CoPilot/style.scss";
import { Avatar, Grid, Typography, Stack, Box } from "@mui/material";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import "./style.scss";
import "../../../pages/Reports/style.scss";
import MailIcon from "@iconify-icons/ic/mail-outline";
import PhoneIcon from "@iconify-icons/bx/bxs-phone";
import { Icon } from "@iconify/react";
import { createRatingDashboard } from "../../../redux/actions/dashboard";
import { getAccountInfo } from "../../../redux/actions/settings";
import { getUserRatings } from "../../../redux/actions/dashboard";
import woman from "../../../assets/img/woman.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const RatingDialog = ({
  onClose,
  selectedValue,
  open,
  userId,
  userName,
  userEmail,
  userRating,
}) => {
  const [personaData, setPersonaData] = useState();
  const { personas, ratingsData, rated } = useSelector(
    (state) => state.dashboard
  );
  const [expanded, setExpanded] = useState(false);
  const [openbox, setOpenbox] = React.useState(false);
  const [showAll, setShowAll] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const { accountInfo } = useSelector((state) => state.settings);
  const [view, setView] = useState("");
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    //    setLoading(true);
    setView("");
  }, [open]);
  useEffect(() => {
    dispatch(getAccountInfo());
  }, []);
  useEffect(() => {
    if (accountInfo) {
      localStorage.setItem("roles", accountInfo?.roles);
    }
  }, [accountInfo]);
  const handleClose = () => {
    onClose(selectedValue);
    setShowAll(false); // Reset 'showAll' state
    // openbox(false);
  };
  useEffect(() => {
    if (personas) {
      setPersonaData(personas);
    }
  }, [personas]);
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
    dispatch(getUserRatings(userId, token));
  }, []);
  useEffect(() => {
    if (rated) {
      toast.success("Submitted ratings.");
      setRate(0);
      setComment("");
      //   onClose();
    }
  }, [rated]);

  const handleSubmitRating = () => {
    const token = localStorage.getItem("token"); // Retrieve token from localStorage
    if (rate > 0 && userId && token) {
      const ratingData = {
        customer_id: userId,
        submitter_id: accountInfo?.user_id,
        submitter_name: `${accountInfo?.first_name || ""} ${
          accountInfo?.last_name || ""
        }`,
        rating: rate,
        reviews: comment,
        submitted_at: moment().format("YYYY-MM-DD"),
      };

      try {
        dispatch(createRatingDashboard(token, ratingData, userId));
      } catch (error) {
        console.error("Error handling rating submission:", error);
      }
    }
  };

  const handleViewAll = () => {
    setShowAll(true); // Expand to show all reviews
  };
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Dialog
      id="ratingDialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
      style={{
        overflowY: "hidden !important",
        fontFamily: "Gotham",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: "absolute",
          top: "12px",
          right: "8px",
          color: "black",
        }}
      >
        <CloseIcon style={{ height: "18px", width: "18px" }} />
      </IconButton>
      <div
        style={{
          width: isMobile ? "370px" : "600px",
          marginTop: isMobile ? "15%" : "",
          overflowY: view === "reviews" ? "hidden" : "auto",
          height: isMobile ? "660px" : "",
        }}
      >
        <div style={{ paddingTop: "12%" }}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="Bio Image"
                src={getOrAssignAvatar(userId)}
                sx={{
                  display: "flex",
                  width: isMobile ? 65 : 100,
                  height: isMobile ? 65 : 100,
                }}
              />
            </Grid>
            <Grid
              item
              xs={7}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              style={{
                marginBottom: isMobile ? "0px" : "8px",
              }}
            >
              <Typography variant="body1" className="blue bold-700">
                <Stack direction="row" gap={1} style={{ lineHeight: "1" }}>
                  <span
                    style={{
                      fontSize: isMobile ? "13px" : "20px",
                      marginBottom: "0px",
                    }}
                  >
                    {userName}
                  </span>
                  <Box
                    component="span"
                    className="ratingBox"
                    style={{
                      width: "fit-content",
                      borderRadius: "4px",
                      paddingTop: "0px",
                      paddingRight: "4px",
                      height: isMobile ? "16px" : "",
                      marginTop: isMobile ? "-4px" : "",
                    }}
                  >
                    <React.Fragment
                      className="ratingBox"
                      style={{
                        paddingTop: isMobile ? "0px" : "",
                      }}
                    >
                      {Images.STAR_ICON}
                    </React.Fragment>
                    <Typography
                      variant="body1"
                      className="darker-gray bold-700"
                      style={{
                        fontSize: isMobile ? "9px" : "10px",
                        fontWeight: "100",
                        marginLeft: "3px",
                        lineHeight: "16px",
                      }}
                    >
                      {ratingsData?.message?.customer_rating_average?.toFixed(
                        2
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Typography>
              <div
                style={{
                  display: "flex",
                  color: "#6F7174",
                  paddingTop: "3%",
                  fontFamily: "'Gotham'",
                }}
              >
                <Icon
                  icon={MailIcon}
                  style={{
                    fontSize: isMobile ? "11px" : "12px",
                    marginTop: isMobile ? "1px" : "",
                  }}
                />
                <p
                  style={{
                    fontSize: isMobile ? "10px" : "11px",
                    lineHeight: isMobile ? "12px" : "11px",
                    paddingLeft: "4px",
                    fontWeight: "500",
                    marginBottom: "2%",
                  }}
                >
                  {userEmail}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  color: "#6F7174",
                  paddingTop: isMobile ? "0%" : "2%",
                }}
              >
                <Icon
                  icon={PhoneIcon}
                  style={{ fontSize: isMobile ? "10px" : "12px" }}
                />
                <p
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: isMobile ? "10px" : "11px",
                    lineHeight: "11px",
                    paddingLeft: "4px",
                    fontWeight: "500",
                  }}
                >
                  778-999-1234
                </p>
              </div>
            </Grid>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: isMobile ? "6%" : "3%" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "7%",
                  color: "#4A4C4D",
                  fontSize: isMobile ? "11px" : "1rem",
                  marginBottom: "2%",
                }}
              >
                <h1>
                  {ratingsData?.message?.customer_rating_average?.toFixed(2)}
                </h1>
              </Grid>
              <Grid item xs={12} alignItems="center" justifyContent="center">
                <Rate
                  allowHalf
                  value={
                    (ratingsData &&
                      ratingsData?.message?.customer_rating_average &&
                      Math.round(
                        ratingsData?.message?.customer_rating_average * 10
                      ) / 10) ||
                    0
                  }
                  character={({ index }) => (
                    <StarIcon
                      style={{
                        fontSize: isMobile ? "23px" : "1.9rem", // Maintain size consistency
                        color:
                          index + 1 <=
                            (ratingsData &&
                              ratingsData?.message?.customer_rating_average &&
                              Math.round(
                                ratingsData?.message?.customer_rating_average *
                                  10
                              ) / 10) || 0
                            ? "#fadb14"
                            : "darkgrey", // Yellow for filled, grey for empty
                        marginRight: isMobile ? "-7px" : "",
                      }}
                    />
                  )}
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
                style={{
                  color: "#6F7174",
                  marginTop: isMobile ? "0%" : "1%",
                  fontWeight: "400",
                  fontSize: isMobile ? "14px" : "16px",
                }}
              >
                <h6
                  style={{ fontWeight: "300", fontSize: isMobile ? "9px" : "" }}
                >
                  Based on{" "}
                  <a
                    className="viewReviews"
                    onClick={() => {
                      setView("reviews");
                    }}
                    style={{
                      cursor: "pointer",
                      fontSize: isMobile ? "10px" : "",
                    }}
                  >
                    {(ratingsData &&
                      ratingsData?.message?.customer_rating_count) ||
                      0}{" "}
                    reviews
                  </a>
                </h6>
              </Grid>
            </Grid>
          </Grid>
          {view === "reviews" ? (
            <div className="listReviews">
              {ratingsData &&
              ratingsData?.message?.all_reviews &&
              ratingsData?.message?.all_reviews.length > 0 ? (
                <>
                  <div className="reviewContainer">
                    {ratingsData.message.all_reviews
                      .slice(
                        0,
                        showAll ? ratingsData.message.all_reviews.length : 2
                      ) // Show only 2 reviews unless 'showAll' is true
                      .map((item, i) => (
                        <div className="reviewItem" key={i}>
                          <div
                            className="itemHead"
                            style={{ fontSize: "12px" }}
                          >
                            <Rate
                              allowHalf
                              value={item.rating}
                              onChange={(value) =>
                                console.log("New rate:", value)
                              } // Example handler
                            />
                            <h5>{item.submitterName || "Test User"}</h5>
                            <p style={{ fontSize: "12px" }}>
                              {item.submittedAt
                                ? moment
                                    .utc(item.submittedAt)
                                    .local()
                                    .format("MMM DD, YYYY")
                                : ""}
                            </p>
                          </div>

                          <div>
                            <p
                              style={{
                                maxHeight: expanded ? "none" : "4em",
                                overflow: "hidden",
                                transition: "max-height 0.3s ease-in-out",
                                color: "#707070",
                                fontWeight: "300",
                              }}
                            >
                              {item.reviews || "--"}
                            </p>

                            {item.reviews && item.reviews.length > 3 && (
                              <Button
                                className="read-more"
                                type="text"
                                style={{
                                  fontSize: "10px",
                                  color: "#0050bc",
                                  display: "contents",
                                }}
                                onClick={toggleExpand}
                              >
                                {expanded ? "Read Less" : "Read More"}
                              </Button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  {!showAll &&
                    ratingsData.message.all_reviews.length > 2 && ( // Show 'View All' only if there are more than 2 reviews
                      <Button
                        className="viewAll"
                        type="text"
                        onClick={handleViewAll}
                      >
                        View more
                      </Button>
                    )}
                </>
              ) : (
                <Empty />
              )}
            </div>
          ) : (
            <>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: "3%" }}
              >
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    marginTop: "8%",
                    fontSize: isMobile ? "11px" : "20px",
                    color: "#6F7174",
                  }}
                >
                  <h3>Rate customer</h3>
                </Grid>
                <p
                  style={{
                    color: "#6F7174",
                    marginTop: "4px",
                    fontWeight: "400",
                    fontSize: isMobile ? "10px" : "11px",
                    marginBottom: "2%",
                  }}
                >
                  Would you do business with this customer again?
                </p>
                <Grid item xs={12} alignItems="center" justifyContent="center">
                  <Rate
                    allowHalf
                    value={rate}
                    onChange={(value) => setRate(value)}
                    character={({ index }) => (
                      <StarIcon
                        style={{
                          fontSize: isMobile ? "1.5rem" : "2rem", // Maintain size consistency
                          color: index + 1 <= rate ? "#fadb14" : "white", // Yellow for filled stars, white for empty
                          //  border: index + 1 <= rate ? 'none' : '1px solid #fadb14', // Add border for empty stars
                          stroke: "#fadb14", // Yellow outline for empty stars
                          strokeWidth: 2, // Th
                          marginLeft: isMobile ? "-2px" : "",
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} sm={2} md={2} lg={2} xl={2}></Grid>
                <Grid item xs={10} sm={8} md={8} lg={8} xl={8}>
                  <div
                    className="comments"
                    style={{ marginTop: "10%", fontSize: "16px" }}
                  >
                    <label
                      style={{
                        color: "#6F7174",
                        fontWeight: "500",
                        marginBottom: "10px",
                        display: "block",
                        fontSize: isMobile ? "11px" : "16px",
                      }}
                    >
                      Comments
                    </label>
                    <Input.TextArea
                      rows={isMobile ? 5 : 3}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      block
                      className="btnSubmit"
                      style={{
                        backgroundColor: "#002857",
                        borderRadius: "20px",
                        marginTop: isMobile ? "21%" : "15%",
                        marginBottom: "40px",
                        padding: isMobile ? "15px" : "20px",
                        color: "white",
                        fontSize: isMobile ? "11px" : "",
                      }}
                      onClick={() => {
                        handleSubmitRating();
                        handleClose();
                      }}
                      disabled={rate === 0}
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default RatingDialog;
