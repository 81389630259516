import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Typography, List, ListItem, ListItemText, Button, Divider, Card, Container } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment"
import McIcon from "../../../assets/img/icons/mc.svg"
import VisaIcon from "../../../assets/img/icons/visa.svg"
import EllipseIcon from "../../../assets/img/icons/ellipse-gray.svg"
import PlanSelector from './ChangePlan';
import { types } from "../../../redux/constant/modals";
import { show } from "../../../redux/actions/modals";
import ModalCurrentPlan from './ChangePlan/modalCurrentPlan';
import comingSoon from '../../../assets/img/comingSoon-icon.svg';
import { getCurrentProduct, plansDataCurrency } from "../../../redux/actions/subscriptionManagement";
import { getSubscription } from "../../../redux/actions/subscription"

import { getPaymentTransactions } from "../../../redux/actions/payment"
function SubscriptionDetails() {
  const dispatch = useDispatch();
  const { currentProduct, plansData } = useSelector((state) => state.subscriptionManagement);
  const { accountInfo: { user_id } } = useSelector(
    (state) => state.settings
  )

  const { data: subscription } = useSelector((state) => state.subscription)
  const [showPlanSelector, setShowPlanSelector] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (user_id) {
      dispatch(getPaymentTransactions({ user_id }))
    }
  }, [user_id])
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (currentProduct && plansData.length > 0) {
      plansData.map((plan) => ((currentProduct?.name.includes(plan.name)) ? setCurrentPlan(plan.id) : ""))
    }
  }, [currentProduct, plansData]);
  const handlePlanChangeClick = () => {
    setShowPlanSelector(true);
  };

  const handlePaymentMethod = () => {
    dispatch(show(types.PAYMENT_METHODS))
  }

  const handlePaymentHistory = () => {
    dispatch(show(types.PAYMENT_HISTORY))
  }

  const handleCancelSubscription = () => {
    dispatch(show(types.CANCEL_SUBSCRIPTION))
  }

  useEffect(() => {
    dispatch(plansDataCurrency());
    dispatch(getCurrentProduct());
    dispatch(getSubscription({ user_id }))
  }, [])
  const { transactions, loading } = useSelector(
    (state) => state.payment
  )
  const getBrandDescription = ({ brand, last4 }) => (
    <div style={{ display: 'flex', gap: '8px', fontSize: "14px" }}> {/* Adjusted gap here */}
      <img style={{ width: "30px" }} src={brand === 'mastercard' ? McIcon : VisaIcon} alt={brand} />
      <div style={{ marginLeft: '10px', display: 'flex', gap: "5px", fontSize: "14px" ,alignItems:"center"}}> {/* Adding margin left for additional spacing */}
        {[...Array(3)].map((_, i) => (
          <img key={i} src={EllipseIcon} alt="" />
        ))}
        {last4}
      </div>
    </div>
  );

  return (
    <Container maxWidth="x-lg" style={{ marginTop: isMobile ? "-13%" : "2%", paddingLeft: isMobile ? "0px" : "1px" }}>
      {showPlanSelector ?
        <PlanSelector onClose={() => setShowPlanSelector(false)} /> :
        <>

          <h1 className="title" style={{ display: isMobile ? "contents" : "none", marginLeft: isMobile ? "20px" : "" }}>
            Subscription
          </h1>
          <Typography variant="h6" style={{ color: "#002857", fontSize: isMobile ? "12px" : "16px", fontFamily: "Plus Jakarta Sans", fontWeight: "600", marginTop: isMobile ? "45px" : "15px" }}>
            Plan Details
          </Typography>
          <Card variant="outlined" sx={{ my: 4, height: isMobile ? "255px" : "auto", width: isMobile ? '100%' : '100%', borderRadius: "15px", border: "1px solid #C8C8CE", marginTop: isMobile ? "5%" : "2%" }}>
            <List component="nav">
              <ListItem style={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                <ListItemText primary="Business plan" secondary="Great choice for multi-location and omni channel businesses"
                  primaryTypographyProps={{
                    style: {
                      color: "#002857",
                      fontWeight: "500",
                      fontSize: isMobile ? "12px" : "14px",
                      fontFamily: '"Gotham", sans-serif',
                    }
                  }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize: isMobile ? "12px" : "14px",
                      fontWeight: "500",
                      color: "rgb(2 2 2 / 44%)",
                      paddingTop: "10px",
                      marginRight: isMobile ? "7.5%" : "0%",
                      overflow: isMobile ? "hidden" : "none",
                      whiteSpace: isMobile ? "nowrap" : "normal",
                      textOverflow: isMobile ? "ellipsis" : 'clip',
                    
                    }
                  }}
                />
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.544 7.045C20.848 7.471 21 7.685 21 8C21 8.316 20.848 8.529 20.544 8.955C19.178 10.871 15.689 15 11 15C6.31 15 2.822 10.87 1.456 8.955C1.152 8.529 1 8.315 1 8C1 7.684 1.152 7.471 1.456 7.045C2.822 5.129 6.311 1 11 1C15.69 1 19.178 5.13 20.544 7.045Z" stroke="#002857" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8Z" stroke="#002857" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                {/* <ArrowForwardIosIcon fontSize="small" /> */}
              </ListItem>
              <Divider style={{ margin: isMobile ? "8px" : "15px", color: "#C8C8CE" }} />


              <ListItem button onClick={handlePlanChangeClick}>
                <ListItemText primary="Change plan" primaryTypographyProps={{
                  style: {
                    color: "#002857",
                    marginTop: isMobile ? "3%" : "0%",
                    marginBottom: isMobile ? '4%' : "0%",
                    fontWeight: "500",
                    fontSize: isMobile ? "12px" : "14px",
                    paddingLeft: isMobile ? "12px" : "0px",
                 
                  }
                }} />
                        <ArrowForwardIosIcon style={{ fontSize: isMobile ? "18px" : "small", marginTop: isMobile ? "2px" : "0px", color: "002857" ,marginRight:isMobile? "20px":"0"}} />

                {/* <ArrowForwardIosIcon style={{ fontSize: isMobile ? "18px" : "small", marginTop: isMobile ? "0px" : "0px", color: "#002857" }} /> */}
              </ListItem>
              <Divider style={{ margin: isMobile ? "8px" : "15px", color: "#C8C8CE" }} />

              <ListItem>
                <ListItemText primary="Buy extra data" secondary="Get additional data to buy match more records, generate more recommendations and more." primaryTypographyProps={{
                  style: {
                    color: "#002857",
                    fontWeight: "500",
                    fontSize: isMobile ? "12px" : "14px",
                    
                  }
                }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize: isMobile ? "12px" : "14px",
                      fontWeight: "500",
                      color: "rgb(2 2 2 / 44%)",
                      paddingTop: "10px",
                      overflow: isMobile ? "hidden" : "normal",
                      whiteSpace: isMobile ? "nowrap" : "normal",
                      textOverflow: isMobile ? "ellipsis" : 'clip',
                      marginRight: isMobile ? "7.5%" : "0%",
                    }
                  }} />

                {isMobile && (
                  <img
                    src={comingSoon}
                    alt="comingSoon"
                    style={{
                      marginRight: "0%",
                      width: "70px",
                      alignSelf: "flex-start",
                      marginTop: '5%'
                    }}
                  />
                )}
                {!isMobile && (
                  <img
                    src={comingSoon}
                    alt="comingSoon"
                  />
                )}


              </ListItem>
            </List>
          </Card>
          <Typography variant="h6" style={{ color: "#002857", marginTop: isMobile ? "11%" : "6%", fontSize: isMobile ? "12px" : "16px", fontFamily: "Plus Jakarta Sans", fontWeight: "600" }}>
            Payment Info
          </Typography>
          <Card variant="outlined" sx={{ my: 4, height: isMobile ? "300px" : "auto", width: isMobile ? '100%' : '100%', borderRadius: "15px", border: "1px solid #C8C8CE", marginTop: isMobile ? "5%" : "2%", marginBottom: isMobile ? "20%" : "5%" }}>
            <List component="nav">
              <ListItem>
                <ListItemText
                  primary="Next payment"
                  primaryTypographyProps={{
                    style: {
                      color: "#002857",
                      fontWeight: "500",
                      fontSize: isMobile ? "12px" : "14px",
                     
                    },
                  }}
                  secondary={
                    <div>
                      {/* Display the subscription period end date */}
                      <div style={{
                        fontSize: isMobile ? "12px" : "14px",
                        color: "#999999",
                        fontWeight: "500",
                      }}>
                        {moment.unix(subscription?.current_period_end).format("MMMM D, YYYY")}
                      </div>

                      {/* Map over each transaction and display relevant details */}
                      {transactions?.data?.map(({ id, created, description, brand, last4, amount, receipt_url }, i) => (
                        <div style={{
                          marginTop: '4px',
                          color: "#002857",
                          fontWeight: "700",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px", // This should apply to the flex container
                        }}>
                          {getBrandDescription({ brand, last4 })}
                        </div>
                      ))}
                    </div>
                  }
                  secondaryTypographyProps={{
                    style: {
                      fontSize: isMobile ? "12px" : "14px", // This will remain constant
                      fontWeight: "500",
                      color: "rgb(2 2 2 / 44%)",
                      paddingTop: "10px",
                    },
                  }}
                />
              </ListItem>
              <Divider style={{ margin: isMobile ? "2px" : "11px", color: "#C8C8CE" }} />
              <ListItem onClick={handlePaymentMethod} style={{ cursor: "pointer" }}>
                <ListItemText primary="Manage payment method" primaryTypographyProps={{
                  style: {
                    color: "#002857", fontWeight: "500",
                    fontSize: isMobile ? "12px" : "14px",
                    marginTop: isMobile ? "2%" : "0%",
                  }
                }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize: isMobile ? "12px" : "14px",
                      fontWeight: "500",
                      color: "rgb(2 2 2 / 44%)",
                      paddingTop: "10px",
                    }
                  }} />
                <ArrowForwardIosIcon style={{ fontSize: isMobile ? "18px" : "small", marginTop: isMobile ? "2px" : "0px", color: "002857" }} />

              </ListItem>
              <Divider style={{ margin: isMobile ? "8px" : "15px", color: "#C8C8CE" }} />
              <ListItem>
                <ListItemText primary="Redeem gift or promo code" primaryTypographyProps={{
                  style: {
                    color: "#002857",
                    fontWeight: "500",
                    fontSize: isMobile ? "12px" : "14px",
                    marginTop: isMobile ? "2%" : "0%",
                  }
                }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize: isMobile ? "12px" : "14px",
                      fontWeight: "500",
                      color: "rgb(2 2 2 / 44%)",
                      paddingTop: "10px",
                    }
                  }} />

                {/* {!isMobile && <img src={comingSoon} alt="comingSoon" />} */}
                {isMobile && (
                  <img
                    src={comingSoon}
                    alt="comingSoon"
                    style={{
                      marginRight: "0%",
                      width: "70px",
                      alignSelf: "flex-start",
                      marginTop: '5%'
                    }}
                  />
                )}
                {!isMobile && (
                  <img
                    src={comingSoon}
                    alt="comingSoon"
                  />
                )}
              </ListItem>
              <Divider style={{ margin: isMobile ? "8px" : "15px", color: "#C8C8CE" }} />
              <ListItem onClick={handlePaymentHistory} style={{ marginBottom: "15px", cursor: "pointer" }}>
                <ListItemText primary="View payment history" primaryTypographyProps={{
                  style: {
                    color: "#002857",
                    fontWeight: "500",
                    fontSize: isMobile ? "12px" : "14px",
                    marginTop: isMobile ? "2%" : "0%",
                  }
                }}
                  secondaryTypographyProps={{
                    style: {
                      fontSize: isMobile ? "12px" : "14px",
                      fontWeight: "500",
                      color: "rgb(2 2 2 / 44%)",
                      paddingTop: "10px",
                    }
                  }} />
                <ArrowForwardIosIcon style={{ fontSize: isMobile ? "18px" : "small", marginTop: isMobile ? "3px" : "0px", color: "#002857" }} />
              </ListItem>
            </List>
          </Card>
          <Card variant="outlined" style={{ width: isMobile ? '100%' : '100%', height: isMobile ? "50px" : "auto", borderRadius: isMobile ? "5px" : "3px", marginBottom: "4%" }}>
            <List component="nav" style={{ border: "#999999" }}>
              <Button onClick={handleCancelSubscription} fullWidth color="error" variant="text" sx={{
            fontWeight: "600",
                // mb: 0
              }}>
                Cancel Subscription
              </Button>

            </List>
          </Card>

          <ModalCurrentPlan open={open} onClose={() => setOpen(false)} isCurrent={true} currentPlan={currentPlan} />
        </>}
    </Container>
  );
}

export default SubscriptionDetails;
