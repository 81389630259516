import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "./style.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { setNewPassword } from "../../../redux/actions/auth";

const NewPassword = (props) => {
  const { email, newPassword, error, loading, setStep } = props;
  const dispatch = useDispatch();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const { newPasswordRequired } = useSelector((state) => state.auth);
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required("Enter your new password")
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&#^()_\-+=<>{}:;,./"']/,
        `Password must contain at least one special character`
      ),
    confirmPassword: yup
      .string()
      .required("Confirm your password")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setButtonClicked(false);
      dispatch(setNewPassword({ email: email, code: newPassword.code, newPassword: values.newPassword, newPasswordRequired: newPasswordRequired}));
      setButtonClicked(true);
    },
  });

  useEffect(() => {
    if (newPassword.reset && !loading && error===null) {
  
      setStep(0);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [newPassword.reset, loading, error]);

  return (
    <Box className="newPassword">
      <Typography variant="body1" className="formTitle">
        New Password
      </Typography>

      <Typography variant="body1" className="formHead">
        Enter new password
      </Typography>
      <TextField
        name="newPassword"
        type={showPassword1 ? "text" : "password"}
        placeholder="Password"
        variant="outlined"
        sx={{ marginBottom: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword1}
              >
                {showPassword1 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
      />

      <Typography variant="body1" className="formHead">
        Confirm Password
      </Typography>
      <TextField
        name="confirmPassword"
        type={showPassword2 ? "text" : "password"}
        placeholder="Password"
        variant="outlined"
        sx={{ marginBottom: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword2}
              >
                {showPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
      />

      {error !== "Invalid token specified" &&
        error &&
        buttonClicked && ( // unable to set new password due to some error (API fail???) => try again to set password
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}

      <Button
        variant="contained"
        className="newPasswordButton"
        onClick={formik.handleSubmit}
      >
        Submit
      </Button>
    </Box>
  );
};

export default NewPassword;
