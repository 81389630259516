import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import PlanCard from './planCard';
import ModalChangePlan from './modalChangePlan';

import { getCurrentProduct, plansDataCurrency } from "../../../../redux/actions/subscriptionManagement";



const PlanSelector = ({ onClose }) => {
    const dispatch = useDispatch();
    const { currentProduct, plansData } = useSelector((state) => state.subscriptionManagement);
    const [loading, setLoading] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(0);  // Assuming 'Business' plan is the current plan
    const [open, setOpen] = useState(false);
    const [currentPlan, setCurrentPlan] = useState(0);
    const handleSelectPlan = (planId) => {
        setSelectedPlan(planId);
    };
    useEffect(() => {
        if (currentProduct) {
            plansData.map((plan) => ((currentProduct?.name.includes(plan.name)) ? setCurrentPlan(plan.id) : ""))
            setLoading(false);
        }
    }, [currentProduct]);

    useEffect(() => {
        dispatch(getCurrentProduct());
        dispatch(plansDataCurrency());
    }, []);
    useEffect(()=>{
        if(plansData){
            setLoading(false)
        }
    },[plansData])

    return (
        <>
            {currentPlan > 0 ? (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} >
                            <Typography variant="h6" style={{ color: "#002957", marginTop: "15px", marginBottom: "15px", fontSize: "1.2rem" }}>
                            Change Plan
                            </Typography>
                        </Grid>
                        {plansData.map((plan) => (
                            <Grid item xs={12} sm={12} md={12} key={plan.id} style={{ display: "flex", flexDirection: "row" }}>
                                <span style={{ flex: "1", lineHeight:"0px" }}>
                                    {((plan.id === selectedPlan && selectedPlan !== 0) || (plan.id === currentPlan && selectedPlan === 0)) ? <svg style={{
                                        marginLeft: "-9px",
                                        marginTop: "-25px",
                                        marginBottom: "-61px"
                                    }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="11" r="11" fill="#02AFEF" />
                                        <path d="M9.59035 17.1113L4.88892 11.8972L6.06427 10.5937L9.59035 14.5043L17.158 6.11133L18.3334 7.41486L9.59035 17.1113Z" fill="white" />
                                    </svg> : ""}
                                    <PlanCard plan={plan} isCurrent={((plan.id === selectedPlan && selectedPlan !== 0) || (plan.id === currentPlan && selectedPlan === 0))} onSelectPlan={() => handleSelectPlan(plan.id)} currentPlan={currentPlan} />
                                </span>
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10, p: 2 }}>
                        <Button variant="contained" onClick={onClose} style={{
                            width: "15%", borderRadius: "20px", backgroundColor:"#999999",
                            height: "45px",
                            color: "white",
                            minWidth: "fit-content",
                            boxShadow:"none",
                            

                        }}>Back</Button>
                       <Button
    variant="contained"
    color="primary"
    style={{
        width: "15%",
        borderRadius: "20px",
        marginLeft: "15px",
        height: "45px",
        minWidth: "fit-content",
        color: "white",
        backgroundColor: currentPlan === selectedPlan || selectedPlan === 0 ? '#999999' : undefined, // Apply gray background if disabled
    }}
    disabled={currentPlan === selectedPlan || selectedPlan === 0}
    onClick={() => setOpen(true)}
>
    Continue
</Button>
                        <ModalChangePlan open={open} onClose={() => setOpen(false)} isCurrent={true} selectedPlan={selectedPlan} currentPlan={currentPlan} />
                    </Box>
                </Box>) : <></>}</>
    );
};

export default PlanSelector;
